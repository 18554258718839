import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    SimpleGrid,
    List,
    ListItem,
    Stack,
    Spinner,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onSettingsChange } from '../redux/Features/Settings';
import { useEffect, useState } from 'react';
import { getActiveHouseModelsList, getManagerThumbs } from '../utils/webiny';

export default function Investments({ onModalOpen }) {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [Managers, setManagers] = useState();
    const [HouseModels, setHouseModels] = useState();

    const setManagersAndModels = async () => {
        // set up form with active managers and models from webiny
        await getManagerThumbs()
            .then((res) => {
                console.log(res);
                let managers = res.data.data.listManagers.data;
                managers.push(' ');
                setManagers(managers);
            })
            .catch((err) =>
                console.log(
                    `setManagersAndModels failed on Allocations Page, ${err}`
                )
            );
        await getActiveHouseModelsList()
            .then((res) => {
                let hms = res.data.data.listHouseModels.data;
                console.log(hms);
                setHouseModels(hms);
            })
            .catch((err) =>
                console.log(
                    `setManagersAndModels failed on Allocations Page, ${err}`
                )
            );
    };

    useEffect(() => {
        setManagersAndModels();
    }, []);

    return (
        <>
            <Box position={'relative'} w="83%" borderBottomRadius={'xl'}>
                <Container
                    maxW={'8xl'}
                    zIndex={10}
                    position={'relative'}
                    borderRadius={'xl'}
                >
                    <Stack
                        flex={1}
                        color={'mkyfive.900'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 5, xl: 5 }}
                    >
                        <Box mb={{ base: 8, md: 8 }}>
                            <Heading
                                color={'mkytwo.500'}
                                mb={2}
                                fontSize={{ base: '3xl', md: '3xl' }}
                                textAlign={'left'}
                            >
                                Investing with Us
                            </Heading>
                            <Container
                                maxW={'8xl'}
                                zIndex={10}
                                position={'relative'}
                                bg={'mkytwo.500'}
                                borderRadius={'xl'}
                                boxShadow="dark-lg"
                            >
                                <Text
                                    fontSize={'lg'}
                                    color={'mkyfive.50'}
                                    textAlign={'left'}
                                    pt={3}
                                    px={'.75em'}
                                >
                                    Investing is different for everyone
                                    depending on multiple factors such as your
                                    career, income, age, risk tolerance and
                                    more. If you would answer some questions to
                                    help us understand your individual
                                    situation, we will provide you with our
                                    recommendation of how you should invest your
                                    money across our managers and models.
                                </Text>
                                <Button
                                    rounded={'full'}
                                    fontWeight={'normal'}
                                    bg="mkyfive.50"
                                    _hover={{
                                        bg: 'mkytwo.500',
                                        color: 'mkyfive.50',
                                        border: '2px',
                                        borderColor: 'mkyfive.50',
                                        boxShadow: 'dark-lg',
                                    }}
                                    px={6}
                                    my={5}
                                    onClick={() => {
                                        dispatch(
                                            onSettingsChange({
                                                name: 'ModalType',
                                                value: 'Questions',
                                            })
                                        );
                                        onModalOpen();
                                    }}
                                >
                                    Yes, show me please!
                                </Button>
                                <Text
                                    fontSize={'lg'}
                                    color={'mkyfive.50'}
                                    textAlign={'left'}
                                    px={'.75em'}
                                >
                                    You can learn more about all our investment
                                    models by clicking on them below. Our
                                    portfolio managers each manage their own
                                    unique model fund and our Marketocracy
                                    Masters (MM) models are managed by teams of
                                    our managers that comprise our investment
                                    committee.
                                </Text>
                                <Button
                                    rounded={'full'}
                                    fontWeight={'normal'}
                                    bg="mkyfive.50"
                                    _hover={{
                                        bg: 'mkytwo.500',
                                        color: 'mkyfive.50',
                                        border: '2px',
                                        borderColor: 'mkyfive.50',
                                        boxShadow: 'dark-lg',
                                    }}
                                    px={6}
                                    my={5}
                                    onClick={() =>
                                        navigate('/allocations', {
                                            state: { from: 'Skip Form' },
                                        })
                                    }
                                >
                                    Open Account
                                </Button>
                            </Container>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            <Container w="83%" maxW={'8xl'} zIndex={10} position={'relative'}>
                <Heading
                    color={'mkytwo.500'}
                    mb={5}
                    fontSize={{ base: '3xl', md: '3xl' }}
                    textAlign={'left'}
                >
                    Investment Choices
                </Heading>
                {HouseModels && Managers ? (
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
                        <List
                            border="4px"
                            borderRadius={'xl'}
                            borderColor={'mkyone.700'}
                            boxShadow="dark-lg"
                        >
                            <ListItem>
                                <Text
                                    fontSize={'lg'}
                                    w={'100%'}
                                    py={2}
                                    bg={'mkyone.700'}
                                    color={'mkyfive.50'}
                                >
                                    House Models
                                </Text>
                            </ListItem>
                            {HouseModels.map((model, i) => (
                                <ListItem key={i}>
                                    <Button
                                        w={'100%'}
                                        _hover={{
                                            bg: 'mkyfive.50',
                                            color: 'mkytwo.900',
                                            boxShadow: 'dark-lg',
                                        }}
                                        borderRadius={0}
                                        bg="mkytwo.500"
                                        color="mkyfive.50"
                                        onClick={() =>
                                            navigate(
                                                `/house/${model.allocationMetaData.label}`
                                            )
                                        }
                                    >
                                        {model.allocationMetaData.label}
                                    </Button>
                                </ListItem>
                            ))}
                        </List>
                        <List
                            border="4px"
                            borderRadius={'xl'}
                            borderColor={'mkyone.700'}
                            boxShadow="dark-lg"
                        >
                            <Text
                                fontSize={'lg'}
                                w={'100%'}
                                py={2}
                                bg={'mkyone.700'}
                                color={'mkyfive.50'}
                            >
                                Portfolio Managers
                            </Text>
                            {Managers.map((manager, i) =>
                                manager !== ' ' ? (
                                    <ListItem key={i}>
                                        <Button
                                            w={'100%'}
                                            borderRadius={0}
                                            bg="mkytwo.500"
                                            color="mkyfive.50"
                                            _hover={{
                                                bg: 'mkyfive.50',
                                                color: 'mkytwo.900',
                                                boxShadow: 'dark-lg',
                                            }}
                                            onClick={() =>
                                                navigate(
                                                    `/manager/${manager.name}`
                                                )
                                            }
                                        >
                                            {manager.name}
                                        </Button>
                                    </ListItem>
                                ) : (
                                    <ListItem key={i}>
                                        <Button
                                            w={'100%'}
                                            borderRadius={0}
                                            bg="mkytwo.500"
                                            color="mkyfive.50"
                                            _hover={{
                                                bg: 'mkytwo.500',
                                                color: 'mkyfive.50',
                                            }}
                                        ></Button>
                                    </ListItem>
                                )
                            )}
                        </List>
                    </SimpleGrid>
                ) : (
                    <Spinner size={'xl'} />
                )}
            </Container>
        </>
    );
}
