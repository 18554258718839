export const analyzeRiskScoreValidate = (sum) => {
    if (sum < 1) return '50% MM Stable Dividend Model 	& 50% U.S. Bond Fund';
    else if (sum > 1 && sum < 2)
        return '50% MM Stable Dividend Model, 25% MM High ROI Dividend Model, 25% MM International Dividend Model';
    else if (sum > 2.01 && sum < 3)
        return '50% MM High ROI Dividend Model, 25% MM International Dividend Model, 25% MM REIT Model';
    else if (sum > 3.01 && sum < 4)
        return '20% MM High ROI Dividend Model, 17% MM International Growth Model, 7% into each of the portfolio managers funds';
};

const ManagerFunds = (v) => {
    let val = v;
    return {
        'Tony Mitchell - APMIF Fund Model': val,
        'Dan Wieman – DWVMF Fund Model': val,
        'Eugene Groysman – EMF Fund Model': val,
        'Glen Brownworth – GMF Fund Model': val,
        'Gorden Lam – GVF Fund Model': val,
        'John Archer – TAB Fund Model': val,
        'Kyle Harden – HFF Fund Model': val,
        'Nate Pile – NMF Fund Model': val,
    };
};

// TODO make a gui way of doing this
// Function controls how the risk score fills out the allocation page
export const analyzeRiskScore = (sum) => {
    if (sum < 1) {
        return {
            'MM Stable Dividend Model': 50,
            'MM US Bond Model': 50,
        };
    } else if (sum >= 1 && sum < 2) {
        return {
            'MM Stable Dividend Model': 50,
            'MM High ROI Dividend Model': 25,
            'MM International Dividend Model': 25,
        };
    } else if (sum > 2 && sum < 3) {
        return {
            'MM High ROI Dividend Model': 50,
            'MM International Dividend Model': 25,
            'MM REIT Model': 25,
        };
    } else if (sum > 3 && sum < 4) {
        return {
            'MM High ROI Dividend Model': 20,
            'MM International Growth Model': 17,
            ...ManagerFunds(7),
        };
    } else if (sum >= 4)
        return {
            'MM High ROI Dividend Model': 5,
            'MM International Dividend Model': 5,
            ...ManagerFunds(10),
        };
};

export const analyzeRisk2 = (sum, conditions, payloads) => {
    let ret = {};
    let winningCondition = 0;

    sum = Number(sum);

    let conditions_list = Object.keys(conditions).map((val) => conditions[val]);
    let payloads_list = Object.keys(payloads).map((val) => payloads[val]);

    for (let i = conditions_list.length; i > -1; i--) {
        let condition_idx = conditions_list[i];
        let payload_idx = payloads_list[i];

        // console.log(
        //     'INDEXES',
        //     condition_idx,
        //     payload_idx,
        //     Array.isArray(condition_idx)
        // );

        if (!Array.isArray(condition_idx)) {
            if (sum > condition_idx) {
                ret = payload_idx;
                winningCondition = condition_idx;
            }
        } else {
            if (sum > condition_idx[0] && condition_idx[1] > sum) {
                ret = payload_idx;
                winningCondition = condition_idx;
            }
        }

        if (Object.keys(ret).length !== 0) break;
    }

    console.log('Condition applied', winningCondition);

    return ret;
};

export const validateData = (raw) =>
    Object.keys(raw)
        .filter((d) => raw[d] !== undefined && raw[d] !== null && raw[d] !== '')
        .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
        }, {});

export const convertData = (raw) =>
    Object.keys(raw)
        .filter((d) => raw[d] !== undefined && raw[d] !== null && raw[d] !== '')
        .reduce((obj, key) => {
            obj[key] = Number(raw[key]);
            return obj;
        }, {});

export const filterStrings = (raw) =>
    Object.keys(raw)
        .filter(
            (d) =>
                typeof raw[d] !== 'string' &&
                raw[d] !== undefined &&
                raw[d] !== null &&
                raw[d] !== ''
        )
        .reduce((obj, key) => {
            obj[key] = raw[key];
            return obj;
        }, {});

export const summify = (r) =>
    Object.keys(r).reduce((sum, number) => {
        return sum + r[number];
    }, 0);
