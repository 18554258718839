import React, { Component } from 'react';
import CookieConsent from 'react-cookie-consent';

export default class Cookie extends Component {
    render() {
        return (
            <CookieConsent
                debug={true}
                location="bottom"
                expires={365}
                style={{
                    background: '#21149c',
                    color: '#e3e6e8',
                    textAlign: 'left',
                    borderRadius: '10px',
                    justifyContent: 'center',
                }}
                buttonStyle={{
                    background: '#e3e6e8',
                    color: '#40529c',
                    borderRadius: '20px',
                    padding: '5px 15px',
                    borderColor: '#40529c',
                    borderWidth: '2px',
                    justifyContent: 'center',
                }}
            >
                We use cookies and similar technologies to help personalize
                content and provide a better experience. By clicking I
                understand or turning an option on in Cookie Preferences, you
                agree to this, as outlined in our{' '}
                <a
                    href="https://marketocracy-front-end.s3.us-east-2.amazonaws.com/PDFs/Online+Privacy+Policy+v1.1.pdf"
                    style={{ color: '#67bdea' }}
                    target={'_blank'}
                    rel="noopener noreferrer"
                >
                    Cookie Policy
                </a>
                . To change preferences or withdraw consent, please update your
                cookie preferences in your browser.
            </CookieConsent>
        );
    }
}
