import {
    Avatar,
    Badge,
    Box,
    Checkbox,
    HStack,
    Icon,
    IconButton,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
} from '@chakra-ui/react';
import * as React from 'react';
import { Rating } from './Rating';
import { members } from './data';

export const MemberTable = (props) => {
    const getAllocationAmount = (alloc, key) => {
        console.log(alloc);
        return alloc.filter((a) => a.Name == key);
    };

    let models = [
        'APMIF',
        'DWVMF',
        'EMF',
        'GMF',
        'GVF',
        'TAB',
        'HFF',
        'NMF',
        'OMF',
        'MMHigh',
        'MMStable',
        'MMIntlDividend',
        'MMIntlGrowth',
        'MMUSBond',
        'MMCorpBond',
        'MMESG',
        'MMREIT',
        'MMComm',
    ];

    return (
        <Table {...props}>
            <Thead>
                <Tr>
                    {/* <Th>
          <HStack spacing="3">
            <Checkbox />
            <HStack spacing="1">
              <Text>Name</Text>
              <Icon as={IoArrowDown} color="muted" boxSize="4" />
            </HStack>
          </HStack>
        </Th> */}
                    <Th color="mkyfive.50">Client</Th>
                    <Th color="mkyfive.50">Email</Th>
                    <Th color="mkyfive.50">APMIF</Th>
                    <Th color="mkyfive.50">DWVMF</Th>
                    <Th color="mkyfive.50">EMF</Th>
                    <Th color="mkyfive.50">GMF</Th>
                    <Th color="mkyfive.50">GVF</Th>
                    <Th color="mkyfive.50">TAB</Th>
                    <Th color="mkyfive.50">HFF</Th>
                    <Th color="mkyfive.50">NMF</Th>
                    <Th color="mkyfive.50">OMF</Th>
                    <Th color="mkyfive.50">High ROI</Th>
                    <Th color="mkyfive.50">Stable</Th>
                    <Th color="mkyfive.50">Int. Dividend</Th>
                    <Th color="mkyfive.50">Int. Growth</Th>
                    <Th color="mkyfive.50">US Bond</Th>
                    <Th color="mkyfive.50">Corporate Bond</Th>
                    <Th color="mkyfive.50">ESG</Th>
                    <Th color="mkyfive.50">REIT</Th>
                    <Th color="mkyfive.50">Commodities</Th>
                    <Th color="mkyfive.50">Rating</Th>
                </Tr>
            </Thead>
            <Tbody>
                {props.members.map((member, i) => {
                    let allocAmounts = [];

                    if (member.Allocation.length != 0) {
                        models.forEach((al) => {
                            let temp = getAllocationAmount(
                                member.Allocation,
                                al
                            )[0];
                            console.log('YOLO', al, temp);

                            allocAmounts.push(
                                temp && typeof temp != 'undefined'
                                    ? temp.Amount
                                    : '0'
                            );
                        });
                    } else {
                        allocAmounts = Array.apply(null, Array(18)).map(
                            Number.prototype.valueOf,
                            0
                        );
                    }

                    return (
                        <Tr key={i}>
                            <Td>
                                <HStack spacing="3">
                                    <Box>
                                        <Text fontWeight="medium">
                                            {`${member.FirstName} ${member.LastName}`}
                                        </Text>
                                    </Box>
                                </HStack>
                            </Td>
                            {/* <Td>
            <Badge size="sm" colorScheme={member.status === 'active' ? 'green' : 'red'}>
              {member.status}
            </Badge>
          </Td> */}
                            <Td>
                                <Text color="mkyfive.50">
                                    {member.Email ?? ''}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[0]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[1]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[2]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[3]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[4]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[5]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[6]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[7]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[8]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[9]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[10]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[11]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[12]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[13]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[14]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[15]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[16]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="mkyfive.50">
                                    {allocAmounts[17]}
                                </Text>
                            </Td>
                            <Td>
                                <Text color="muted">
                                    <Rating
                                        defaultValue={member.rating}
                                        size="xl"
                                    />
                                </Text>
                            </Td>
                            {/* <Td>
            <HStack spacing="1">
              <IconButton
                icon={<FiTrash2 fontSize="1.25rem" />}
                variant="ghost"
                aria-label="Delete member"
              />
              <IconButton
                icon={<FiEdit2 fontSize="1.25rem" />}
                variant="ghost"
                aria-label="Edit member"
              />
            </HStack>
          </Td> */}
                        </Tr>
                    );
                })}
            </Tbody>
        </Table>
    );
};
