import { Avatar, Box, Flex, useColorModeValue } from '@chakra-ui/react'
import * as React from 'react'

export const CardWithAvatar = (props) => {
  const { children, avatarProps, ...rest } = props
  return (
    <Flex
      direction="column"
      alignItems="center"
      borderRadius="xl"
      border="1px  solid #40529c"
      padding="8"
      position="relative"
      bg={useColorModeValue('mkyfive.50', 'mkyfour.900')}
      boxShadow='dark-lg'
      shadow={{
        md: 'base',
      }}
      {...rest}
    >
      <Box 
        position="absolute" 
        inset="0" 
        height="165"  
        bg={useColorModeValue('mkytwo.500', 'mkyone.900')}
        roundedTop="inherit" 
      />
      <Avatar size="2xl" {...avatarProps} />
      {children}
    </Flex>
  )
}
