import {
    Box,
    Container,
    Heading,
    Text,
    Link,
    List,
    ListItem,
    Flex,
    Popover,
    PopoverTrigger,
    Portal,
    PopoverContent,
    PopoverArrow,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
    Spinner,
} from '@chakra-ui/react';
// import styled from 'styled-components';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { toast, Zoom } from 'react-toastify';
import { useParams } from 'react-router-dom';
import { FrostButton } from '../components/buttons/FrostButton';
import { PurpleButton } from '../components/buttons/PurpleButton';
import { useSelector, useDispatch } from 'react-redux';
import { onSettingsChange } from '../redux/Features/Settings';
import { getSpecificManagerData } from '../utils/webiny';

import dayjs from 'dayjs';

import { RichTextRenderer } from '@webiny/react-rich-text-renderer';
import IntroSection from '../components/introSection';

const Managers = ({ onModalOpen }) => {
    const [managerName, setManagerName] = useState('');
    const [articles, setArticles] = useState([]);
    const [data, setData] = useState({});
    const settingsStore = useSelector((store) => store.settings);
    const dispatch = useDispatch();
    const params = useParams();

    const openModal = (where) => {
        console.log('go', where);
        dispatch(
            onSettingsChange({
                name: 'ModalType',
                value: 'Questions',
            })
        );
        dispatch(
            onSettingsChange({
                name: 'ModalFrom',
                value: where,
            })
        );
        onModalOpen();
    };

    useEffect(() => {
        let name = params.name;
        if (params.name) setManagerName(params.name);
        else {
            console.log('error getting managerName issue', params);
            toast.error('Error getting managerName issue', {
                containerId: 'B',
                transition: Zoom,
            });
        }
    }, [params]);

    const getManagerData = async (manager) => {
        console.log('retriving', manager);
        await getSpecificManagerData(manager)
            .then((res) => {
                console.log('Manager Data', res.data.data.getManagers.data);
                setData(res.data.data.getManagers.data);
            })
            .catch((err) => console.log('Manager Fetching Failed', err));
    };

    useEffect(() => {
        // let filtered = ManagerData.Manager.filter(
        //     (d) => d.name === managerName
        // );

        if (managerName) {
            getManagerData(managerName);
        }

        // if (filtered.length > 0) {
        // let sortedArticles = filtered[0].articles
        //     .slice()
        //     .sort((a, b) => new Date(b.date) - new Date(a.date));
        // setArticles(sortedArticles);
        // } else {
        // TODO: Navigate home
        // navigate('/');
        // }
    }, [managerName]);

    // useEffect(() => {
    //     console.log(data.graphs);
    // }, [data]);

    return (
        <Container
            mt="10"
            maxW="7xl"
            p="0"
            rounded={{
                sm: 'lg',
            }}
        >
            {Object.keys(data).length ? (
                <>
                    <IntroSection data={data} />
                    <Box my={5}>
                        <Heading
                            color={'mkytwo.500'}
                            px="5px"
                            mb="5px"
                            fontSize={{ base: '3xl', md: '3xl' }}
                            textAlign="left"
                        >
                            {data.title}
                        </Heading>
                        <Text
                            borderRadius={'xl'}
                            shadow={{
                                sm: 'dark-lg',
                            }}
                            bg="mkytwo.500"
                            py={5}
                            color={'mkyfive.50'}
                            size="lg"
                            px={5}
                            textAlign="left"
                        >
                            {data.strategy[0].data.text}
                        </Text>
                    </Box>
                    <Flex
                        flexDirection={{ base: 'column', md: 'row' }}
                        justify={'space-around'}
                        alignItems={'center'}
                        my={5}
                        // py={5}
                    >
                        <Link
                            textDecoration={'none'}
                            _hover={{
                                textDecoration: 'none',
                            }}
                            href={data.gip}
                            paddingBottom={{ base: '5', md: '0' }}
                            isExternal={true}
                        >
                            <FrostButton>
                                View {data.name}'s GIPs composite returns
                            </FrostButton>
                        </Link>
                        {settingsStore.SurveyComplete ||
                        settingsStore.Authorized ? (
                            <Link
                                textDecoration={'none'}
                                _hover={{
                                    textDecoration: 'none',
                                }}
                                href={data.modelPerformance}
                                isExternal={true}
                            >
                                <FrostButton>
                                    View {data.name}'s model performance{' '}
                                </FrostButton>
                            </Link>
                        ) : (
                            <Popover trigger="hover">
                                <PopoverTrigger>
                                    <Link
                                        textDecoration={'none'}
                                        _hover={{
                                            textDecoration: 'none',
                                        }}
                                    >
                                        <FrostButton isDisabled={true}>
                                            View {data.name}'s model performance{' '}
                                        </FrostButton>
                                    </Link>
                                </PopoverTrigger>
                                <Portal>
                                    <PopoverContent>
                                        <PopoverArrow />
                                        <PopoverCloseButton />
                                        <PopoverBody pt={'6'}>
                                            In compliance with SEC marketing
                                            rules, you must complete our
                                            investor survey to qualify to view
                                            the model performance.
                                        </PopoverBody>
                                        <PopoverFooter>
                                            <PurpleButton
                                                w={'100%'}
                                                onClick={() =>
                                                    openModal('managerPage')
                                                }
                                            >
                                                Click here to complete the
                                                survey
                                            </PurpleButton>
                                        </PopoverFooter>
                                    </PopoverContent>
                                </Portal>
                            </Popover>
                        )}
                    </Flex>
                    <Box my={5}>
                        <Heading
                            color={'mkytwo.500'}
                            px="5px"
                            mb="5px"
                            fontSize={{ base: '3xl', md: '3xl' }}
                            textAlign="left"
                        >
                            Bio
                        </Heading>
                        <Text
                            borderRadius={'xl'}
                            shadow={{
                                sm: 'dark-lg',
                            }}
                            bg="mkytwo.500"
                            py={5}
                            color={'mkyfive.50'}
                            size="lg"
                            px={5}
                            textAlign="left"
                        >
                            <RichTextRenderer data={data.bio} />
                            <Link
                                href={data.link}
                                textDecoration={'none'}
                                _hover={{
                                    textDecoration: 'none',
                                }}
                            >
                                {' '}
                                HulbertRatings.com
                            </Link>
                            <br /> <br />
                            {data.bio2 ? (
                                <RichTextRenderer data={data.bio2} />
                            ) : (
                                <></>
                            )}
                        </Text>
                    </Box>
                    {data.articles.length > 0 && (
                        <Heading
                            color={'mkytwo.500'}
                            px="5px"
                            mb="5px"
                            fontSize={{ base: '3xl', md: '3xl' }}
                            textAlign="left"
                            my={2}
                        >
                            Articles
                        </Heading>
                    )}
                    {data.articles.length > 0 && (
                        <Box
                            borderRadius={'xl'}
                            shadow={{
                                sm: 'dark-lg',
                            }}
                            bg="mkytwo.500"
                            py={5}
                            color={'mkyfive.50'}
                        >
                            <List textAlign={'left'} px={5}>
                                {data.articles.slice(0, 6).map((l, i) => (
                                    <ListItem mb={'5'}>
                                        <Link
                                            key={i}
                                            role="group"
                                            href={l.href}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            // color="mkytwo.500"
                                            fontWeight={500}
                                            fontSize="xl"
                                        >
                                            <Text size="lg" color="muted">
                                                {l.title}
                                                {' | '}
                                                {dayjs(l.date).format(
                                                    'MMMM DD, YYYY'
                                                )}
                                            </Text>
                                        </Link>
                                    </ListItem>
                                ))}
                            </List>
                        </Box>
                    )}
                </>
            ) : (
                <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="blue.500"
                    size="xl"
                />
            )}
        </Container>
    );
};

export default Managers;
