import {
    Input,
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
} from '@chakra-ui/react';

const HookInput = ({
    name,
    register,
    type = 'text',
    err = false,
    helperText = false,
    placeholder = false,
    required = false, // false | 'Required'
    styles = {},
    pattern = {},
    validate = {},
    ...rest
}) => {
    return (
        <FormControl isInvalid={err}>
            <FormLabel {...styles} htmlFor={name.replace(' ', '')}>
                {name}
            </FormLabel>
            <Input
                {...rest}
                id={name.replace(' ', '')} // name is react hook form id
                type={type}
                isInvalid={err ? true : false}
                errorBorderColor="crimson"
                placeholder={placeholder ? placeholder : name}
                {...register(`${name.replace(' ', '')}`, {
                    required: required,
                    pattern: pattern,
                    validate: validate,
                })}
            />
            {err && err.message ? (
                <FormErrorMessage>{err.message}</FormErrorMessage>
            ) : (
                <FormHelperText>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
};

export default HookInput;
