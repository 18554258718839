// import { Input } from '@chakra-ui/react';
import {
    Select,
    FormControl,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
} from '@chakra-ui/react';

const HookSelect = ({
    name,
    options,
    register,
    type = 'Select',
    err = false,
    helperText = false,
    placeholder = false,
    required = false, // false | 'Required'
    styles = {},
    pattern = {},
    validate = {},
}) => {
    return (
        <FormControl isInvalid={err} {...styles}>
            <FormLabel htmlFor={name.replace(' ', '')}>{name}</FormLabel>
            <Select
                id={name.replace(' ', '')} // name is react hook form id
                type={type}
                // icon={<ArrowDownIcon />}
                isInvalid={err ? true : false}
                errorBorderColor="crimson"
                placeholder={placeholder ? placeholder : name}
                {...register(`${name.replace(' ', '')}`, {
                    required: required,
                    pattern: pattern,
                    validate: validate,
                })}
            >
                {options.map((o, i) => (
                    <option key={i} value={o.Weight}>
                        {o.Value}
                    </option>
                ))}
            </Select>
            {err && err.message ? (
                <FormErrorMessage>{err.message}</FormErrorMessage>
            ) : (
                <FormHelperText>{helperText}</FormHelperText>
            )}
        </FormControl>
    );
};

export default HookSelect;
