import { configureStore } from '@reduxjs/toolkit';
// import counterReducer from '../features/counter/counterSlice'
import SurveyReducer from './Features/Survey';
import SettingsReducer from './Features/Settings';

export const store = configureStore({
    reducer: {
        survey: SurveyReducer,
        settings: SettingsReducer,
    },
});

store.subscribe(() => {
    localStorage.setItem('reduxState', JSON.stringify(store.getState()));
});
