import {
    Box,
    Button,
    Container,
    Heading,
    Text,
    SimpleGrid,
    List,
    ListItem,
    Stack,
    HStack,
    PopoverContent,
    PopoverArrow,
    PopoverHeader,
    PopoverCloseButton,
    PopoverBody,
    PopoverFooter,
    Portal,
    PopoverTrigger,
    Popover,
    Tooltip,
    useMediaQuery,
} from '@chakra-ui/react';
import SignUpData from './../data/data.json';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onSettingsChange } from '../redux/Features/Settings';
import { useEffect, useState, useCallback } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faSolid,
    faPeopleRoof,
    faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

export default function Investments({ onModalOpen }) {
    let navigate = useNavigate();
    const dispatch = useDispatch();

    const [accountType, setAccountType] = useState('');
    const [accountSignUp, setAccountSignUp] = useState([]);

    const [isLargerThan600] = useMediaQuery('(min-width: 600px)');

    const RoofIcon = () => {
        return <FontAwesomeIcon icon={faPeopleRoof} />;
    };

    useEffect(() => {
        setAccountSignUp(SignUpData.SignUpLinks[accountType]);
    }, [accountType]);

    const GenChildButton = useCallback(
        (s, i) => {
            if (s === 'Description') return;
            if (isLargerThan600)
                return (
                    <Tooltip label={'This is ' + accountSignUp[s]?.Description}>
                        <Button
                            leftIcon={RoofIcon}
                            borderRadius={25}
                            bg="mkyfive.50"
                            color="mkytwo.500"
                            _hover={{
                                cursor: 'pointer',
                                boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                                bg: 'mkytwo.500',
                                color: 'mkyfive.50',
                            }}
                            minWidth="15vw"
                            fontSize="17px"
                            variant={accountType === s ? 'solid' : 'outline'}
                            onClick={() =>
                                window.open(accountSignUp[s]?.Link, '_black')
                            }
                        >
                            {s}
                        </Button>
                    </Tooltip>
                );
            else
                return (
                    <Popover>
                        <PopoverTrigger>
                            <Button
                                leftIcon={RoofIcon}
                                borderRadius={25}
                                bg="mkyfive.50"
                                color="mkytwo.500"
                                _hover={{
                                    cursor: 'pointer',
                                    boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                                    bg: 'mkytwo.500',
                                    color: 'mkyfive.50',
                                }}
                                minWidth="15vw"
                                fontSize="17px"
                                variant={
                                    accountType === s ? 'solid' : 'outline'
                                }
                            >
                                {s}
                            </Button>
                        </PopoverTrigger>
                        <Portal>
                            <PopoverContent>
                                <PopoverArrow />
                                <PopoverHeader>Confirmation</PopoverHeader>
                                <PopoverCloseButton />
                                <PopoverBody>
                                    This is {accountSignUp[s].Description}. Are
                                    you sure you would like to proceed?
                                </PopoverBody>
                                <PopoverFooter>
                                    <Button
                                        colorScheme="mkyone"
                                        onClick={() =>
                                            window.open(
                                                accountSignUp[s].Link,
                                                '_black'
                                            )
                                        }
                                    >
                                        Proceed
                                    </Button>
                                </PopoverFooter>
                            </PopoverContent>
                        </Portal>
                    </Popover>
                );
        },
        [isLargerThan600, accountType, accountSignUp]
    );

    return (
        <>
            <Box position={'relative'} w="83%" borderBottomRadius={'xl'}>
                <Container
                    maxW={'8xl'}
                    zIndex={10}
                    position={'relative'}
                    borderRadius={'xl'}
                >
                    <Stack
                        flex={1}
                        color={'mkyfive.900'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 5, xl: 5 }}
                    >
                        <Box mb={{ base: 8, md: 8 }}>
                            <Heading
                                color={'mkytwo.500'}
                                mb={5}
                                fontSize={{ base: '3xl', md: '3xl' }}
                                textAlign={'left'}
                            >
                                What type of account are you opening?
                            </Heading>
                            <Container
                                maxW={'8xl'}
                                zIndex={10}
                                position={'relative'}
                                bg={'mkytwo.500'}
                                borderRadius={'xl'}
                                boxShadow="dark-lg"
                                color="mkyfive.50"
                            >
                                <Stack
                                    flex={1}
                                    color={'mkyfive.900'}
                                    justify={{ lg: 'center' }}
                                    py={{ base: 4, md: 5, xl: 5 }}
                                >
                                    <Box my={{ base: 8, md: 8 }}>
                                        <Stack
                                            direction={['column', 'row']}
                                            spacing="24px"
                                            display={'flex'}
                                            justifyContent="space-around"
                                            // w="100vw"
                                        >
                                            {Object.keys(
                                                SignUpData.SignUpLinks
                                            ).map((s, i) => (
                                                <Tooltip
                                                    label={
                                                        SignUpData.SignUpLinks[
                                                            s
                                                        ].Description
                                                    }
                                                >
                                                    <Button
                                                        leftIcon={RoofIcon}
                                                        minWidth="15vw"
                                                        border="1px solid"
                                                        borderColor="mkyfive.50"
                                                        borderRadius={25}
                                                        {...(accountType === s
                                                            ? {
                                                                  bg: 'mkyfive.50',
                                                                  color: 'mkytwo.500',
                                                                  _hover: {
                                                                      cursor: 'pointer',
                                                                      boxShadow:
                                                                          '0 0 2px rgba(0,0,0,0.3)',
                                                                      bg: 'mkytwo.500',
                                                                      color: 'mkyfive.50',
                                                                  },
                                                              }
                                                            : {
                                                                  bg: 'mkytwo.500',
                                                                  color: 'mkyfive.50',
                                                                  _hover: {
                                                                      cursor: 'pointer',
                                                                      boxShadow:
                                                                          '0 0 2px rgba(0,0,0,0.3)',
                                                                      bg: 'mkyfive.50',
                                                                      color: 'mkytwo.500',
                                                                  },
                                                              })}
                                                        fontSize="20px"
                                                        onClick={() =>
                                                            setAccountType(s)
                                                        }
                                                    >
                                                        {s}
                                                    </Button>
                                                </Tooltip>
                                            ))}
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Container>
                        </Box>
                    </Stack>
                </Container>
            </Box>
            {accountSignUp ? (
                <>
                    <Box
                        position={'relative'}
                        w="83%"
                        borderBottomRadius={'xl'}
                    >
                        <Container
                            maxW={'8xl'}
                            zIndex={10}
                            position={'relative'}
                            borderRadius={'xl'}
                        >
                            <Stack
                                flex={1}
                                color={'mkyfive.900'}
                                justify={{ lg: 'center' }}
                                py={{ base: 4, md: 5, xl: 5 }}
                            >
                                <Box mb={{ base: 8, md: 8 }}>
                                    <Heading
                                        color={'mkytwo.500'}
                                        mb={5}
                                        fontSize={{ base: '3xl', md: '3xl' }}
                                        textAlign={'left'}
                                    >
                                        Choose the type of {accountType}{' '}
                                        account, click to continue
                                    </Heading>
                                    <Container
                                        maxW={'8xl'}
                                        zIndex={10}
                                        position={'relative'}
                                        bg={'mkytwo.500'}
                                        borderRadius={'xl'}
                                        boxShadow="dark-lg"
                                        color="mkyfive.50"
                                    >
                                        <Stack
                                            flex={1}
                                            color={'mkyfive.900'}
                                            justify={{ lg: 'center' }}
                                            py={{ base: 4, md: 5, xl: 5 }}
                                        >
                                            <Box my={{ base: 8, md: 8 }}>
                                                <Stack
                                                    direction={[
                                                        'column',
                                                        'row',
                                                    ]}
                                                    spacing="24px"
                                                    display={'flex'}
                                                    justifyContent="space-around"
                                                >
                                                    {Object.keys(
                                                        accountSignUp
                                                    ).map((s, i) =>
                                                        GenChildButton(s, i)
                                                    )}
                                                </Stack>
                                            </Box>
                                        </Stack>
                                    </Container>
                                </Box>
                            </Stack>
                        </Container>
                    </Box>
                </>
            ) : (
                <></>
            )}
        </>
    );
}
