let envs;

if (process.env.REACT_APP_ENV_API_KEY === 'prod') {
    envs = {
        url: 'https://5m8w7babe3.execute-api.us-east-2.amazonaws.com/prod/',
        contact_url:
            'https://km5vdfidod.execute-api.us-east-2.amazonaws.com/prod/contact',
    };
} else if (process.env.REACT_APP_ENV_API_KEY === 'beta') {
    envs = {
        url: 'https://kgiv5yqa84.execute-api.us-east-2.amazonaws.com/beta/',
        contact_url:
            'https://mgcq49q48c.execute-api.us-east-2.amazonaws.com/beta/contact',
    };
} else {
    envs = {
        url: 'https://2491vsk8w1.execute-api.us-east-2.amazonaws.com/dev/',
        contact_url:
            'https://e43pq91ima.execute-api.us-east-2.amazonaws.com/dev/contact',
    };
}

module.exports = envs;
