import { React, useEffect, useState } from 'react';
import {
    Box,
    Button,
    SimpleGrid,
    Flex,
    Container,
    Stack,
    Heading,
    Text,
    Link,
    Spinner,
    SkeletonText,
} from '@chakra-ui/react';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { CardWithAvatar } from '../components/about/CardWithAvatar';
import data from '../data/data.json';
import { UserInfo } from '../components/about/UserInfo';
import useRedirect from '../components/hooks/redirectHook.jsx';
import { getAboutData, getManagerThumbs } from '../utils/webiny';

import { RichTextRenderer } from '@webiny/react-rich-text-renderer';
import styled from 'styled-components';

export default function About() {
    let navigate = useRedirect();

    let [managers, setManagers] = useState([]);
    let [about, setAbout] = useState([]);

    const getAboutPROD = async () => {
        return await getAboutData('PROD')
            .then((res) => {
                console.log(res);
                setAbout(res.data.data.getAbout.data.summaryRt);
            })
            .catch((res) => {
                console.log('getting manager thumbnails failed', res);
            });
    };

    const getManagers = async () => {
        return await getManagerThumbs()
            .then((res) => {
                setManagers(res.data.data.listManagers.data);
            })
            .catch((res) => {
                console.log('getting manager thumbnails failed', res);
            });
    };

    const customRenderers = {
        // Override the default renderer for "delimiter" block
        delimiter: () => {
            return <br />;
        },
    };

    useEffect(() => {
        getAboutPROD();
        // fetch manager thumbnails
        getManagers();
    }, []);

    return (
        <>
            <Box position={'relative'}>
                <Container maxW={'8xl'} zIndex={10} position={'relative'}>
                    <Stack
                        flex={1}
                        color={'mkyfive.50'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 10, xl: '1em' }}
                    >
                        <Box mb={{ base: 8, md: 8 }}>
                            <Heading
                                color={'mkytwo.500'}
                                mb={2}
                                fontSize={{ base: '3xl', md: '3xl' }}
                                textAlign={'left'}
                            >
                                About Us
                            </Heading>
                            <Container
                                py={3}
                                minW={'8xl'}
                                zIndex={10}
                                position={'relative'}
                                bg={'mkytwo.500'}
                                borderRadius={'xl'}
                                boxShadow="dark-lg"
                            >
                                {about && about.length > 0 ? (
                                    <Text
                                        fontSize={'lg'}
                                        color={'mkyfive.50'}
                                        // textAlign={'left'}
                                        px={'.75em'}
                                    >
                                        <RichTextRenderer
                                            data={about}
                                            renderers={customRenderers}
                                        />
                                    </Text>
                                ) : (
                                    <Stack>
                                        <SkeletonText
                                            width="full"
                                            mt="4"
                                            mb="4"
                                            noOfLines={4}
                                            spacing="4"
                                            skeletonHeight="2"
                                        />

                                        <br />

                                        <SkeletonText
                                            mt="4"
                                            mb="6"
                                            noOfLines={2}
                                            spacing="4"
                                            skeletonHeight="2"
                                        />

                                        <br />

                                        <SkeletonText
                                            mt="6"
                                            mb="4"
                                            noOfLines={4}
                                            spacing="4"
                                            skeletonHeight="2"
                                        />
                                    </Stack>
                                )}
                            </Container>
                        </Box>
                    </Stack>

                    <Flex flex={1} />
                </Container>
            </Box>
            <Container maxW={'8xl'} zIndex={10} position={'relative'} w="83%">
                <Heading
                    color={'mkytwo.500'}
                    mb={5}
                    fontSize={{ base: '3xl', md: '3xl' }}
                    textAlign={'left'}
                >
                    Masters
                </Heading>
                <Box
                    as="section"
                    maxW={{
                        base: 'xs',
                        md: '8xl',
                    }}
                    mx="auto"
                    pb="15px"
                >
                    <SimpleGrid
                        columns={{
                            base: 1,
                            md: 4,
                        }}
                        spacing="15"
                    >
                        {managers ? (
                            managers.map((user) => {
                                const { name, fund, src, tag } = user;
                                return (
                                    <CardWithAvatar
                                        key={name}
                                        avatarProps={{
                                            src,
                                            name,
                                            fund,
                                            tag,
                                        }}
                                    >
                                        <UserInfo
                                            mt="3"
                                            name={name}
                                            fund={fund}
                                            tag={tag}
                                        />
                                        <Button
                                            variant="outline"
                                            bg="mkytwo.500"
                                            color="mkyfive.50"
                                            size="sm"
                                            width="50%"
                                            cursor="pointer"
                                            onClick={() =>
                                                navigate(`/manager/${name}`)
                                            }
                                            rounded={'full'}
                                            _hover={{
                                                bg: 'mkyfive.50',
                                                color: 'mkytwo.500',
                                                border: '2px',
                                                borderColor: 'mkytwo.500',
                                                boxShadow: 'dark-lg',
                                            }}
                                        >
                                            View Profile
                                        </Button>
                                    </CardWithAvatar>
                                );
                            })
                        ) : (
                            <Spinner size={'xl'} />
                        )}
                    </SimpleGrid>
                </Box>
            </Container>
        </>
    );
}
