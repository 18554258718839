import React from 'react';
import {
    Box,
    Container,
    Text,
    Heading,
    Image,
    Stack,
    Flex,
    Link,
} from '@chakra-ui/react';
import HouseModel from './../data/data.json';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { getSpecificHouseModelData } from '../utils/webiny';
import Carousel from '../components/carousel';
import IntroSection from '../components/introSection';

export default function House() {
    const [modelName, setModelName] = useState('');
    const [data, setData] = useState({});
    const params = useParams();

    const getHouseModelData = async (hm) => {
        console.log('retriving', hm);
        await getSpecificHouseModelData(hm)
            .then((res) => {
                console.log(
                    'House Model Data',
                    res.data.data.getHouseModels.data
                );
                setData(res.data.data.getHouseModels.data);
            })
            .catch((err) => console.log('Manager Fetching Failed', err));
    };

    useEffect(() => {
        if (params.name) setModelName(params.name);
        else {
        }
    }, [params]);

    useEffect(() => {
        // console.log(HouseModel.Manager);

        // let filtered = HouseModel.Model.filter((d) => d.name === modelName);

        // if (filtered.length > 0) {
        //     console.log(modelName, filtered);
        //     setData(filtered[0]);
        // } else {
        //     // TODO: Navigate home
        //     // navigate('/');
        // }

        if (modelName) {
            getHouseModelData(modelName);
        }
    }, [modelName]);

    return (
        <Container
            maxW={'8xl'}
            zIndex={10}
            position={'relative'}
            borderRadius={'xl'}
        >
            <IntroSection houseModel={true} data={data} />
            <Box>
                <Heading
                    color={'mkytwo.500'}
                    mb={3}
                    mt={4}
                    fontSize={{ base: '2xl', md: '2xl' }}
                    textAlign={'left'}
                >
                    {data.stratTitle}
                </Heading>
                <Box
                    bg={'mkytwo.500'}
                    maxW={'8xl'}
                    zIndex={10}
                    position={'relative'}
                    borderRadius={'xl'}
                    boxShadow="dark-lg"
                >
                    <Text
                        fontSize={'lg'}
                        mb={3}
                        color={'mkyfive.50'}
                        textAlign={'left'}
                        px={'.75em'}
                        py={'.45em'}
                    >
                        {data.strategy}
                    </Text>
                </Box>
                <Heading
                    color={'mkytwo.500'}
                    mb={3}
                    mt={6}
                    fontSize={{ base: '2xl', md: '2xl' }}
                    textAlign={'left'}
                >
                    {data.detTitle}
                </Heading>
                <Box
                    bg={'mkytwo.500'}
                    maxW={'8xl'}
                    zIndex={10}
                    position={'relative'}
                    borderRadius={'xl'}
                    boxShadow="dark-lg"
                >
                    <Text
                        fontSize={'lg'}
                        mb={3}
                        color={'mkyfive.50'}
                        textAlign={'left'}
                        px={'.75em'}
                        py={'.45em'}
                    >
                        {data.details}&nbsp;
                        <Link
                            mt={4}
                            href={data.pdf}
                            target="_blank"
                            rel="noopener noreferrer"
                            color="mkythree.100"
                            textDecoration="underline !important"
                        >
                            {data.click}
                        </Link>
                    </Text>
                </Box>
            </Box>
        </Container>
    );
}
