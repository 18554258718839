import React from 'react';
import {
    Box,
    Heading,
    Container,
    SimpleGrid,
    Button,
    useToast,
    EditablePreview,
    EditableInput,
    Editable,
    Input,
} from '@chakra-ui/react';
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react';
import { getAlgorithmVariables, setAlgorithmVariables } from '../../utils/api';
import { useEffect } from 'react';
import { useState } from 'react';

const ChangeAllocations = () => {
    const toast = useToast();

    const [isHundred, setIsHundred] = useState({});

    const [firstAlloc, setFirstAlloc] = useState({});
    const [secAlloc, setSecAlloc] = useState({});
    const [thirdAlloc, setThirdAlloc] = useState({});
    const [fourthAlloc, setFourthAlloc] = useState({});
    const [fifthAlloc, setFifthAlloc] = useState({});

    const [firstTotal, setFirstTotal] = useState(0);
    const [secondTotal, setSecondTotal] = useState(0);
    const [thirdTotal, setThirdTotal] = useState(0);
    const [fourthTotal, setFourthTotal] = useState(0);
    const [fifthTotal, setFifthTotal] = useState(0);

    const [masterModels, setMasterModels] = useState([]);

    let mapVarKey = {
        'Tony Mitchell': 'APMIF',
        'Dan Wieman': 'DWVMF',
        'Eugene Groysman': 'EMF',
        'Glen Brownworth': 'GMF',
        'Gorden Lam': 'GVF',
        'John Archer': 'TAB',
        'Kyle Harden': 'HFF',
        'Nate Pile': 'NMF',
        'Robert Frazier': 'OMF',
        'High ROI Dividend': 'MMHigh',
        'Stable Dividend': 'MMStable',
        'International Dividend': 'MMIntlDividend',
        'International Growth': 'MMIntlGrowth',
        'U.S. Bond': 'MMUSBond',
        'Corporate Bond': 'MMCorpBond',
        ESG: 'MMESG',
        REIT: 'MMREIT',
        Commodities: 'MMComm',
    };

    let managers = [
        'Tony Mitchell',
        'Dan Wieman',
        'Eugene Groysman',
        'Glen Brownworth',
        'Gorden Lam',
        'John Archer',
        'Kyle Harden',
        'Nate Pile',
    ];
    let models = [
        'High ROI Dividend',
        'Stable Dividend',
        'International Dividend',
        'International Growth',
        'U.S. Bond',
        'Corporate Bond',
        'ESG',
        'REIT',
        'Commodities',
    ];

    const getAlgoVars = async () => {
        await getAlgorithmVariables()
            .then((res) => {
                console.log(res.data.data.getSettings.Settings);
                var settings = res.data.data.getSettings.Settings.Payloads;
                console.log(settings.FirstCondition);

                setFirstAlloc(settings.FirstCondition);
                setSecAlloc(settings.SecondCondition);
                setThirdAlloc(settings.ThirdCondition);
                setFourthAlloc(settings.FourthCondition);
                setFifthAlloc(settings.FifthCondition);

                // toast({
                //     title: 'Check Console for retrieved data.',
                //     description: 'Retrieved Data Successfully!',
                //     status: 'success',
                //     duration: 9000,
                //     isClosable: true,
                // });
            })
            .catch((err) => {
                console.log('getAlgoVars', err);
            });
    };

    useEffect(() => {
        getAlgoVars();
    }, []);

    useEffect(() => {
        console.log(masterModels, fifthAlloc);
    }, [masterModels]);

    useEffect(() => {
        setMasterModels(managers.concat(...models));
    }, [fifthAlloc]);

    const checkIsHundred = () => {
        if (firstTotal !== 100) {
            return false;
        }
        if (secondTotal !== 100) {
            return false;
        }
        if (thirdTotal !== 100) {
            return false;
        }
        if (fourthTotal !== 100) {
            return false;
        }
        if (fifthTotal !== 100) {
            return false;
        }

        return true;
    };

    useEffect(() => {
        setIsHundred(checkIsHundred());
    }, [firstTotal, secondTotal, thirdTotal, fourthTotal, fifthTotal]);

    useEffect(() => {
        console.log('first updated, ', firstAlloc);
    }, [firstAlloc]);

    const updateTotal = (alloc) => {
        var tmp = 0;
        if (Object.values(alloc).length > 0) {
            console.log(Object.values(alloc));
            tmp = Object.values(alloc).reduce((a, b) => a + b);
        }

        if (typeof tmp === 'object' && !Array.isArray(tmp) && tmp !== null) {
            console.log('obj', tmp);
            tmp = 0;
        }

        if (Array.isArray(tmp)) {
            console.log('arr', tmp);
            tmp = 0;
        }

        return tmp;
    };

    // useEffect(() => {
    //     if (Object.values(firstAlloc).length > 0) {
    //         setFirstTotal(Object.values(firstAlloc).reduce((a, b) => a + b));
    //     }
    // }, [firstAlloc]);

    // useEffect(() => {
    //     if (Object.values(secAlloc).length > 0) {
    //         setSecondTotal(Object.values(secAlloc).reduce((a, b) => a + b));
    //     }
    // }, [secAlloc]);

    // useEffect(() => {
    //     if (Object.values(thirdAlloc).length > 0) {
    //         setThirdTotal(Object.values(thirdAlloc).reduce((a, b) => a + b));
    //     }
    // }, [thirdAlloc]);

    // useEffect(() => {
    //     if (Object.values(fourthAlloc).length > 0) {
    //         setFourthTotal(Object.values(fourthAlloc).reduce((a, b) => a + b));
    //     }
    // }, [fourthAlloc]);

    // useEffect(() => {
    //     if (Object.values(fifthAlloc).length > 0) {
    //         setFifthTotal(Object.values(fifthAlloc).reduce((a, b) => a + b));
    //     }
    // }, [fifthAlloc]);

    useEffect(() => {
        console.log('first', firstAlloc);
        console.log('first', firstTotal);
        setFirstTotal(updateTotal(firstAlloc));
    }, [firstAlloc]);
    useEffect(() => {
        console.log(secAlloc);
        console.log('second', secondTotal);
        setSecondTotal(updateTotal(secAlloc));
    }, [secAlloc]);
    useEffect(() => {
        console.log(thirdAlloc);
        console.log('third', thirdTotal);
        setThirdTotal(updateTotal(thirdAlloc));
    }, [thirdAlloc]);
    useEffect(() => {
        console.log(fourthAlloc);
        console.log('fourth', fourthTotal);
        setFourthTotal(updateTotal(fourthAlloc));
    }, [fourthAlloc]);
    useEffect(() => {
        console.log(fifthAlloc);
        console.log('fifth', fifthTotal);
        setFifthTotal(updateTotal(fifthAlloc));
    }, [fifthAlloc]);

    const updateAlgoVars = async () => {
        let Conditions = {
            FirstCondition: 0,
            SecondCondition: [1, 2],
            ThirdCondition: [2, 3],
            FourthCondition: [3, 4],
            FifthCondition: 4,
        };

        console.log('DOLO', firstAlloc);

        await setAlgorithmVariables(
            firstAlloc,
            secAlloc,
            thirdAlloc,
            fourthAlloc,
            fifthAlloc,
            Conditions
        )
            .then((res) => {
                if (res.data.data.saveSettings.Message) {
                    toast({
                        title: 'Successfully set Settings.',
                        description:
                            'Settings set Successfully! Check the MKY Dev/Beta Table',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                }
            })
            .catch((err) => {
                console.log('ERROR:', err);
                toast({
                    title: 'ERROR set Settings.',
                    description: 'ERROR! Check the MKY Dev/Beta Table;' + err,
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            });
    };

    const TableHeader = (X) => <Th>{X}</Th>;

    return (
        <Box minH="100vh">
            <Container
                py={10}
                px={10}
                maxW={'8xl'}
                zIndex={10}
                position={'relative'}
                bg={'mkytwo.500'}
                borderRadius={'xl'}
                boxShadow="dark-lg"
                color="mkyfive.50"
                minH="100vh"
            >
                <Heading pb="3" textAlign={'left'}>
                    Change Allocations
                    <SimpleGrid gap={3} py={5}>
                        <Button
                            {...(isHundred ? {} : { disabled: true })}
                            ml={3}
                            bg={'blue'}
                            onClick={updateAlgoVars}
                        >
                            Submit Allocations
                        </Button>
                    </SimpleGrid>
                </Heading>
                <TableContainer
                    borderRadius="10px"
                    boxShadow="1px 1px 4px #000"
                    overflow={'scroll'}
                >
                    <Table
                        borderRadius="10px"
                        variant="striped"
                        colorScheme="blue"
                        color="black"
                    >
                        <Thead>
                            <Tr
                                bg="mkyfive.50"
                                color="mkyone.900"
                                textAlign="left"
                            >
                                <Th color="black !important" fontSize="m">
                                    Masters & Models
                                </Th>
                                <Th color="black !important" fontSize="m">
                                    0 - 0.9
                                </Th>
                                <Th
                                    color="black !important"
                                    fontSize="m"
                                    isNumeric
                                >
                                    1.0 - 1.9
                                </Th>
                                <Th
                                    color="black !important"
                                    fontSize="m"
                                    isNumeric
                                >
                                    2.0 - 2.9
                                </Th>
                                <Th
                                    color="black !important"
                                    fontSize="m"
                                    isNumeric
                                >
                                    3.0 - 3.9
                                </Th>
                                <Th
                                    color="black !important"
                                    fontSize="m"
                                    isNumeric
                                >
                                    4.0 - 5.0
                                </Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {masterModels.length > 0 &&
                            firstAlloc &&
                            secAlloc &&
                            thirdAlloc &&
                            fourthAlloc &&
                            fifthAlloc
                                ? // master/model name
                                  masterModels.map((d, i) => {
                                      const codeName = mapVarKey[d];

                                      return (
                                          <Tr
                                              bg="mkyfive.50"
                                              color="mkyone.900"
                                              key={i}
                                          >
                                              <Td>{d}</Td>

                                              <Td
                                                  bg="mkyfive.50"
                                                  color="mkyone.900"
                                              >
                                                  <Input
                                                      type={'number'}
                                                      borderBottomColor={'blue'}
                                                      borderBottomStyle={
                                                          'solid'
                                                      }
                                                      borderBottomWidth={3}
                                                      onChange={(evt) => {
                                                          //   console.log(evt.target.value);
                                                          let temp = {
                                                              ...firstAlloc,
                                                          };

                                                          temp[codeName] =
                                                              Number(
                                                                  evt.target
                                                                      .value
                                                              );

                                                          setFirstAlloc(temp);
                                                      }}
                                                      defaultValue={
                                                          firstAlloc[codeName]
                                                      }
                                                      placeholder="0"
                                                  />
                                              </Td>
                                              <Td
                                                  bg="mkyfive.50"
                                                  height="30px"
                                                  color="mkyone.900"
                                              >
                                                  <Input
                                                      borderBottomColor={'blue'}
                                                      borderBottomStyle={
                                                          'solid'
                                                      }
                                                      borderBottomWidth={3}
                                                      onChange={(evt) => {
                                                          let temp = {
                                                              ...secAlloc,
                                                          };

                                                          temp[codeName] =
                                                              Number(
                                                                  evt.target
                                                                      .value
                                                              );

                                                          setSecAlloc(temp);
                                                      }}
                                                      defaultValue={
                                                          secAlloc[codeName]
                                                      }
                                                      placeholder="0"
                                                  />
                                              </Td>
                                              <Td
                                                  bg="mkyfive.50"
                                                  height="30px"
                                                  color="mkyone.900"
                                              >
                                                  <Input
                                                      borderBottomColor={'blue'}
                                                      borderBottomStyle={
                                                          'solid'
                                                      }
                                                      borderBottomWidth={3}
                                                      onChange={(evt) => {
                                                          let temp = {
                                                              ...thirdAlloc,
                                                          };

                                                          temp[codeName] =
                                                              Number(
                                                                  evt.target
                                                                      .value
                                                              );

                                                          setThirdAlloc(temp);
                                                      }}
                                                      defaultValue={
                                                          thirdAlloc[codeName]
                                                      }
                                                      placeholder="0"
                                                  />
                                              </Td>
                                              <Td
                                                  bg="mkyfive.50"
                                                  height="30px"
                                                  color="mkyone.900"
                                              >
                                                  <Input
                                                      borderBottomColor={'blue'}
                                                      borderBottomStyle={
                                                          'solid'
                                                      }
                                                      borderBottomWidth={3}
                                                      onChange={(evt) => {
                                                          let temp = {
                                                              ...fourthAlloc,
                                                          };

                                                          temp[codeName] =
                                                              Number(
                                                                  evt.target
                                                                      .value
                                                              );

                                                          setFourthAlloc(temp);
                                                      }}
                                                      defaultValue={
                                                          fourthAlloc[codeName]
                                                      }
                                                      placeholder="0"
                                                  />
                                              </Td>
                                              <Td
                                                  bg="mkyfive.50"
                                                  height="30px"
                                                  color="mkyone.900"
                                              >
                                                  <Input
                                                      borderBottomColor={'blue'}
                                                      borderBottomStyle={
                                                          'solid'
                                                      }
                                                      borderBottomWidth={3}
                                                      onChange={(evt) => {
                                                          let temp = {
                                                              ...fifthAlloc,
                                                          };

                                                          temp[codeName] =
                                                              Number(
                                                                  evt.target
                                                                      .value
                                                              );

                                                          setFifthAlloc(temp);
                                                      }}
                                                      defaultValue={
                                                          fifthAlloc[codeName]
                                                      }
                                                      placeholder="0"
                                                  />
                                              </Td>
                                          </Tr>
                                      );
                                  })
                                : ''}
                        </Tbody>
                        <Tfoot>
                            <Tr bg="mkyfive.50" color="mkyone.900">
                                <Th fontSize="m">Total</Th>
                                <Th
                                    bg={
                                        firstTotal == 100
                                            ? 'green'
                                            : 'mkyfive.50'
                                    }
                                    color={
                                        firstTotal == 100 ? 'mkyfive.50' : ''
                                    }
                                    fontSize="m"
                                    isNumeric
                                >
                                    {firstTotal ?? 0}
                                </Th>
                                <Th
                                    bg={
                                        secondTotal == 100
                                            ? 'green'
                                            : 'mkyfive.50'
                                    }
                                    color={
                                        secondTotal == 100 ? 'mkyfive.50' : ''
                                    }
                                    fontSize="m"
                                    isNumeric
                                >
                                    {secondTotal ?? 0}
                                </Th>
                                <Th
                                    bg={
                                        thirdTotal == 100
                                            ? 'green'
                                            : 'mkyfive.50'
                                    }
                                    color={
                                        thirdTotal == 100 ? 'mkyfive.50' : ''
                                    }
                                    fontSize="m"
                                    isNumeric
                                >
                                    {thirdTotal ?? 0}
                                </Th>
                                <Th
                                    bg={
                                        fourthTotal == 100
                                            ? 'green'
                                            : 'mkyfive.50'
                                    }
                                    color={
                                        fourthTotal == 100 ? 'mkyfive.50' : ''
                                    }
                                    fontSize="m"
                                    isNumeric
                                >
                                    {fourthTotal ?? 0}
                                </Th>
                                <Th
                                    bg={
                                        fifthTotal == 100
                                            ? 'green'
                                            : 'mkyfive.50'
                                    }
                                    color={
                                        fifthTotal == 100 ? 'mkyfive.50' : ''
                                    }
                                    fontSize="m"
                                    isNumeric
                                >
                                    {fifthTotal ?? 0}
                                </Th>
                            </Tr>
                        </Tfoot>
                    </Table>
                </TableContainer>
            </Container>
        </Box>
    );
};

export default ChangeAllocations;
