export const SAVE_CLIENT_CONTACT = `
mutation($Email: String!, $Phone: String!, $FirstName: String!, $LastName: String!) {
	saveClientContact(
		Input: {
      Email:$Email,
      Phone:$Phone,
      FirstName:$FirstName,
      LastName:$LastName   
		}
  )
	{
    ID
    Message
	}
}
`;

export const SAVE_CLIENT_DATA = `
mutation ( $UserData: UserData! ) {
    saveClientData( Input: $UserData  )
    {
      ID
      Message
    }
}
`;

export const GET_2FA = `
mutation($Email: String!) {
	send2FACode(
		Input: {
                Email:$Email 
		}
  )
	{
    ID
    Message
	}
}
`;

export const VERIFY_2FA = `
mutation ($ID: String!, $Email: String!, $Code: String!) {
    verify2FACode(
      Input:{
          ID: $ID,
          Email: $Email,
          Code: $Code
      }
    )
    {
      ID
      Message
      Token
      FirstName
      LastName
    }
}
`;

export const SAVE_ALLOCATION_TABLE = `
mutation ($ID: String!, $Table: [NewAllocation!]!, $InitalAllocation: Boolean!) {
  saveAllocationTable(Input: {
    ID: $ID,
    Table: $Table,
    InitalAllocation: $InitalAllocation 
  }) {
    ID 
    Message
  }	
}
`;

export const GET_ALLOCATION_TABLE = `
query ($AccountID: String!) {
  getAllocationData(AccountID: $AccountID) {
    Message
    Allocation {
      Name
      Amount
    }
  }
}
`;

export const GET_USER_AND_ALLOCATIONS = `
query {
  getAllUsers {
    Message
    Users {
      FirstName
      LastName
      Allocation {
        Name
        Amount
      }
    }
  }
}
`;

export const GET_ALGORITHM_VARIABLES = `
query {
  getSettings (
    Name: "AlgorithmVariables"
  ) {
    Settings {
      Conditions {
        FirstCondition
        SecondCondition
        ThirdCondition
        FourthCondition
        FifthCondition
      }
      Payloads {
        FirstCondition {
            MMHigh
            MMStable
            MMIntlGrowth
            MMIntlDividend
            MMUSBond
            MMCorpBond
            MMREIT
            MMESG
            MMComm
            APMIF
            DWVMF
            EMF
            GMF
            GVF
            TAB
            HFF
            NMF
            OMF
        }
        SecondCondition {
            MMHigh
            MMStable
            MMIntlGrowth
            MMIntlDividend
            MMUSBond
            MMCorpBond
            MMREIT
            MMESG
            MMComm
            APMIF
            DWVMF
            EMF
            GMF
            GVF
            TAB
            HFF
            NMF
            OMF
        }
        ThirdCondition {
            MMHigh
            MMStable
            MMIntlGrowth
            MMIntlDividend
            MMUSBond
            MMCorpBond
            MMREIT
            MMESG
            MMComm
            APMIF
            DWVMF
            EMF
            GMF
            GVF
            TAB
            HFF
            NMF
            OMF
        }
        FourthCondition {
            MMHigh
            MMStable
            MMIntlGrowth
            MMIntlDividend
            MMUSBond
            MMCorpBond
            MMREIT
            MMESG
            MMComm
            APMIF
            DWVMF
            EMF
            GMF
            GVF
            TAB
            HFF
            NMF
            OMF
        }
        FifthCondition {
            MMHigh
            MMStable
            MMIntlGrowth
            MMIntlDividend
            MMUSBond
            MMCorpBond
            MMREIT
            MMESG
            MMComm
            APMIF
            DWVMF
            EMF
            GMF
            GVF
            TAB
            HFF
            NMF
            OMF
        }
      }
    }
  }
}
`;

export const SAVE_ALGORITHM_VARIABLES = `
mutation (
  $FirstCondition:AllocationPercentages, 
  $SecondCondition:AllocationPercentages,
  $ThirdCondition:AllocationPercentages,
  $FourthCondition:AllocationPercentages,
  $FifthCondition:AllocationPercentages,
  $Conditions:AllocationConditions,
) {
  saveSettings (
    Input: {
      Name:"AlgorithmVariables",
      Settings:{
        Conditions: $Conditions,
        Payloads: {
          FirstCondition: $FirstCondition,
          SecondCondition: $SecondCondition,
          ThirdCondition: $ThirdCondition,
          FourthCondition: $FourthCondition,
          FifthCondition: $FifthCondition
        }
      }
    }
  ) {
    Message
  }
}
`;
