import {
    Container,
    Stack,
    Flex,
    Box,
    Heading,
    Text,
    Button,
    Image,
    useColorModeValue,
    AspectRatio,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onSettingsChange } from '../redux/Features/Settings';

export default function CallToActionWithVideo({ onModalOpen }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    return (
        <Box
            w={'100%'}
            h={'100%'}
            bgGradient="linear(to-r, mkytwo.500, mkythree.300)"
        >
            <Container maxW={{ base: '8xl', lg: 'auto' }}>
                <Stack
                    align={'center'}
                    spacing={{ base: 8, md: 20, lg: 80 }}
                    py={{ base: 20, md: 0 }}
                    direction={{ base: 'column', md: 'row' }}
                >
                    <Stack flex={1} spacing={{ base: 5, md: 10 }}>
                        <Heading
                            lineHeight={1.1}
                            fontWeight={600}
                            fontSize={{ base: '3xl', sm: '4xl', lg: '6xl' }}
                        >
                            <Text
                                as={'span'}
                                noOfLines={2}
                                position={'relative'}
                                zIndex={0}
                                fontSize={{ base: '3xl', lg: '4xl' }}
                                color={useColorModeValue(
                                    'mkyfive.50',
                                    'mkyfour.100'
                                )}
                                mb={'3'}
                            >
                                Best-in-Class <br />
                                Managers
                                <Text
                                    as='sup'
                                    position={'relative'}
                                    zIndex={0}
                                    fontSize={{ base: 'lg', lg: 'xl' }}
                                    color={useColorModeValue(
                                        'mkyfive.50',
                                        'mkyfour.100'
                                    )}
                                    mb={'3'}
                                >
                                    *
                                </Text>
                            </Text>
                            <Text
                                noOfLines={2}
                                as={'span'}
                                fontSize={{ base: 'xl', lg: '2xl' }}
                                color={useColorModeValue(
                                    'mkythree.100',
                                    'mkythree.100'
                                )}
                                mt={'3'}
                            >
                                Low Fees <br />
                                Low Minimums
                            </Text>
                        </Heading>
                        <Stack
                            spacing={{ base: 4, sm: 6 }}
                            justifyContent={{ base: 'center' }}
                            direction={{ base: 'column', sm: 'row' }}
                        >
                            <Button
                                rounded={'full'}
                                size={'lg'}
                                fontWeight={'normal'}
                                borderColor={'mkyfive.50'}
                                border={'2px'}
                                px={6}
                                bg="mkytwo.500"
                                color="mkyfive.50"
                                // transform= 'transform 0.3s ease'
                                cursor="pointer"
                                _hover={{
                                    bg: 'mkyfive.50',
                                    color: 'mkytwo.500',
                                    borderColor: 'mkytwo.500',
                                    border: '2px',
                                    boxShadow: 'dark-lg',
                                    // transform: 'translateY(-8px)',
                                }}
                                onClick={() => {
                                    dispatch(
                                        onSettingsChange({
                                            name: 'ModalType',
                                            value: 'Questions',
                                        })
                                    );
                                    return onModalOpen();
                                }}
                            >
                                Open Account
                            </Button>
                            <Button
                                rounded={'full'}
                                size={'lg'}
                                fontWeight={'normal'}
                                bg="mkyfive.50"
                                color="mkytwo.500"
                                colorScheme="mkytwo.500"
                                border="2px"
                                variant="outline"
                                // transform= 'transform 0.3s ease'
                                cursor="pointer"
                                _hover={{
                                    bg: 'mkytwo.500',
                                    color: 'mkyfive.50',
                                    boxShadow: 'dark-lg',
                                    //    transform: 'tran slateY(-8px)',
                                    // transform: 'translateY(-8px)',
                                }}
                                px={6}
                                onClick={() => navigate('/investments')}
                            >
                                Learn More
                            </Button>
                        </Stack>
                    </Stack>

                    <Flex
                        flex={1}
                        justify={'center'}
                        align={'center'}
                        position={'relative'}
                        w={'full'}
                        display={{ base: 'none', md: 'flex' }}
                    >
                        <AspectRatio width={'full'} maxW="400px" ratio={1 / 2}>
                            <Image
                                pt="25px"
                                alt={'Hero Image'}
                                fit={'cover'}
                                align={'center'}
                                w={'100%'}
                                h={'100%'}
                                src={
                                    'https://marketocracy-front-end.s3.us-east-2.amazonaws.com/logos/mm-cell.png'
                                }
                            />
                        </AspectRatio>
                    </Flex>
                </Stack>
                <Text
                    as="i"
                    noOfLines={8}
                    textAlign={'left'}
                    fontSize={{ base: 'sm', lg: 'xs' }}
                    color={useColorModeValue('mkythree.100', 'mkythree.100')}
                    p={'5'}
                >
                    * Best-in-Class Managers refers to managers selected for
                    their long-term performance that was superior to the
                    majority of the managers competing under the same rules in
                    the original Marketocracy Portfolio Competition between the
                    years of 1999-2018.
                </Text>
            </Container>
        </Box>
    );
}
