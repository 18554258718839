import { HStack, Text, useColorModeValue, VStack } from '@chakra-ui/react'
import * as React from 'react'

export const UserInfo = (props) => {
  const { name, fund, tag, ...stackProps } = props
  return (
    <VStack spacing="1" flex="1" {...stackProps}>
      <HStack>
        <Text 
          fontWeight="bold" 
          color={useColorModeValue('mkyone.900', 'mkyfive.50')}
        >
          {name}
        </Text>
      </HStack>
      <Text
        fontSize="sm"
        textAlign="center"
        noOfLines={2}
        color={useColorModeValue('mkyone.900', 'mkyfive.50')}
      >
        {fund}
      </Text>
      <Text
        fontSize="sm"
        textAlign="center"
        noOfLines={2}
        color={useColorModeValue('mkyone.900', 'mkyfive.50')}
      >
        {tag}
      </Text>
    </VStack>
  )
}
