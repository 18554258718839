import React, { useEffect } from 'react';
import DashboardMenu from '../components/Dashboard/DashboardMenu';
import { Box, Flex, Button } from '@chakra-ui/react';
import ViewAllocations from '../components/Dashboard/viewAllocationContent/ViewAllocations';
import ChangeAllocations from '../components/Dashboard/ChangeAllocations';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const Dashboard = () => {
    const [active, setActive] = React.useState(0);
    const [hidden, setHidden] = React.useState(0);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settingsStore = useSelector((store) => store.settings);

    useEffect(() => {
        if (!settingsStore.AccessLevel) {
            return navigate('/');
        }
    }, []);

    const getPage = () => {
        //Add any components you need for pages
        switch (active) {
            case 1:
                return <ChangeAllocations />;
            default:
                return <ViewAllocations />;
        }
    };

    return (
        <>
            <Box height={'100%'} width={'100%'}>
                <Flex>
                    {hidden ? (
                        <></>
                    ) : (
                        <Box width={'15%'} display={hidden ? 'none' : 'block'}>
                            <DashboardMenu
                                active={active}
                                setActive={(num) => setActive(num)}
                                closeMenu={() => setHidden(1)}
                            />
                        </Box>
                    )}
                    <Box margin="0 auto" width={hidden ? '100%' : '85%'}>
                        {hidden ? (
                            <Button
                                cursor={'pointer'}
                                bg="mkytwo.500"
                                color="mkyfive.50"
                                mb={5}
                                _hover={{
                                    bg: 'mkyfive.50',
                                    color: 'mkytwo.500',
                                    boxShadow: '0 0 2px rgba(0,0,0,0.3)',
                                }}
                                onClick={() => setHidden(0)}
                            >
                                Open Menu
                            </Button>
                        ) : (
                            <></>
                        )}
                        {getPage()}
                    </Box>
                </Flex>
            </Box>
        </>
    );
};

export default Dashboard;
