import axios from 'axios';

let MKY_WEBINYENDPOINT = 'https://dnh3ceklo3oiy.cloudfront.net/cms/read/en-US';

const webinyAxiosMKY = axios.create({
    baseURL: MKY_WEBINYENDPOINT,
    headers: {
        Authorization: `bearer abeec43003fb7a8275c2d04d5a44ccab0f878563e49cdbe9`,
    },
});

export const webinySkeletonGQLQuery = async (name, query, variables = {}) => {
    try {
        return await webinyAxiosMKY.post('', {
            query,
            variables,
        });
    } catch (e) {
        console.error(`${name} Error: `, e);
    }
};

let MKY_GQLEndpoint =
    'https://0ngj0hkhd1.execute-api.us-east-2.amazonaws.com/dev/';

if (process.env.REACT_APP_ENV_API_KEY === 'prod') {
    MKY_GQLEndpoint =
        'https://42ewo7ywv5.execute-api.us-east-2.amazonaws.com/prod/';
} else if (process.env.REACT_APP_ENV_API_KEY === 'beta') {
    MKY_GQLEndpoint =
        'https://9q72nkbrak.execute-api.us-east-2.amazonaws.com/beta/';
}

const axiosMKY = axios.create({
    baseURL: MKY_GQLEndpoint,
    headers: {
        // Authorization: `bearer ${process.env.REACT_APP_GITHUB_PERSONAL_ACCESS_TOKEN}`,
    },
});

export const skeletonGQLQuery = async (name, query, variables = {}) => {
    try {
        return await axiosMKY.post('', {
            query,
            variables,
        });
    } catch (e) {
        console.error(`${name} Error: `, e);
    }
};
