import {
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    CloseButton,
} from '@chakra-ui/react';

const MKYAlert = ({ type, title, description = false, styles = {} }) => (
    <Alert status={type} {...styles} variant="left-accent">
        <AlertIcon />
        <AlertTitle mr={2}>{title}</AlertTitle>
        {description ? (
            <AlertDescription>{description} </AlertDescription>
        ) : (
            <></>
        )}
        <CloseButton position="absolute" right="8px" top="8px" />
    </Alert>
);

export default MKYAlert;
