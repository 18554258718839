import { skeletonGQLQuery } from './apiHelper';
import {
    SAVE_CLIENT_CONTACT,
    SAVE_CLIENT_DATA,
    GET_2FA,
    VERIFY_2FA,
    SAVE_ALLOCATION_TABLE,
    GET_ALLOCATION_TABLE,
    SAVE_ALGORITHM_VARIABLES,
    GET_ALGORITHM_VARIABLES,
    GET_USER_AND_ALLOCATIONS,
} from './queries/user';

// saveClientData(Input:UserData!): Response!
//   saveAllocationTable(Input: AllocationInput!): Response!
//   send2FACode(Input: User2FAInput): Response!
//   verify2FACode(Input: Verify2FAInput): Response!

// mutation($UserContact: UserContact!) {

export const saveClientContact = async (Email, Phone, FirstName, LastName) =>
    await skeletonGQLQuery('saveClientContact', SAVE_CLIENT_CONTACT, {
        Email,
        Phone,
        FirstName,
        LastName,
    });

export const saveClientData = async (UserData) =>
    await skeletonGQLQuery('saveClientData', SAVE_CLIENT_DATA, {
        UserData,
    });

export const get2FA = async (Email) =>
    await skeletonGQLQuery('get2FA', GET_2FA, {
        Email,
    });

export const verify2FA = async (ID, Email, Code) =>
    await skeletonGQLQuery('verify2FA', VERIFY_2FA, {
        ID,
        Email,
        Code,
    });

export const saveAllocationTable = async (ID, Table, InitalAllocation) =>
    await skeletonGQLQuery('saveAllocationTable', SAVE_ALLOCATION_TABLE, {
        ID,
        Table,
        InitalAllocation,
    });

export const getAllocationsTable = async (AccountID) =>
    await skeletonGQLQuery('getAllocationsTable', GET_ALLOCATION_TABLE, {
        AccountID,
    });

export const getAllUsersAllocations = async () =>
    await skeletonGQLQuery(
        'getAllUsersAllocations',
        GET_USER_AND_ALLOCATIONS,
        {}
    );

export const setAlgorithmVariables = async (
    FirstCondition,
    SecondCondition,
    ThirdCondition,
    FourthCondition,
    FifthCondition,
    Conditions
) =>
    await skeletonGQLQuery('setAlgorithmVariables', SAVE_ALGORITHM_VARIABLES, {
        FirstCondition,
        SecondCondition,
        ThirdCondition,
        FourthCondition,
        FifthCondition,
        Conditions,
    });

export const getAlgorithmVariables = async () =>
    await skeletonGQLQuery(
        'getAlgorithmVariables',
        GET_ALGORITHM_VARIABLES,
        {}
    );
