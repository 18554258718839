import React, { useState } from 'react';

import { useNavigate } from 'react-router-dom';

const useRedirect = () => {
    const navigate = useNavigate();

    return (route = '/', state = {}) => navigate(route, { state });
    //     if (closeModal) modalClose();
};

export default useRedirect;
