import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // 1st page
    FirstName: '',
    LastName: '',
    Email: '',
    PhoneNumber: '',
    // 2nd page
    Age: '',
    CollegeGrad: '',
    HighestDegree: '',
    Employed: '',
    // 3rd page
    AnnualIncome: '',
    DiscretionaryIncome: '',
    NetWorth: '',
    FutureDeposits: '',
    // 4th page
    Liquidity: '',
    Goal: '',
    RiskTolerance: '',
    RiskLevel: '',
    // 5th page
    Describe: '',
    FinancialRisk: '',
    RiskPreference: '',
    // TODO MAYBE MAKE INTO ITS OWN THING
    // USER SETUP
};

export const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        surveyHydrate: (state, action) => {
            return action.payload;
        },
        onChange: (state, action) => {
            state[action.payload.name] = action.payload.value;
        },
        onChangeAlt: (state, action) => {
            state = { ...action.payload, ...state };
        },
        surveyLogOut: () => initialState,
    },
});

// Action creators are generated for each case reducer function
// export const { onAge, decrement, incrementByAmount } = surveySlice.actions;
export const { onChange, surveyHydrate, surveyLogOut } = surveySlice.actions;

export default surveySlice.reducer;
