import React from 'react';

import { CardContent } from './../components/managers/CardContent';
import { CardWithAvatar } from './../components/managers/CardWithAvatar';

import { Box, Heading, Text, Flex } from '@chakra-ui/react';

import Carousel from '../components/carousel';

const IntroSection = ({ data, houseModel = false }) => (
    <Box as="section" p={0} position="relative">
        <Flex
            borderRadius={'xl'}
            inset="0"
            bg="mkytwo.500"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ base: 'column' }}
            boxSizing="border-box"
        >
            <Flex
                borderRadius={'xl'}
                justifyContent="center"
                w="full"
                alignItems="center"
                gap={{ base: 10 }}
                flexDirection={{ base: 'column', md: 'row' }}
                py={{
                    base: '20',
                    md: '2em',
                }}
                px={{
                    base: '0',
                    md: '2em',
                }}
                boxSizing="border-box"
            >
                <CardWithAvatar
                    w="100%"
                    h="100%"
                    avatarProps={{
                        src: data.src,
                        name: data.name,
                    }}
                >
                    <CardContent>
                        {houseModel ? (
                            <>
                                <Heading
                                    color={'mkyfive.50'}
                                    mt={4}
                                    fontSize={{ base: '2xl', md: '4xl' }}
                                    textAlign={'center'}
                                >
                                    {data.mm}
                                </Heading>
                                <Heading
                                    color={'mkyfive.50'}
                                    mt={1}
                                    fontSize={{ base: 'xl', md: '3xl' }}
                                    textAlign={'center'}
                                >
                                    {data.houseModel}
                                </Heading>
                                <Heading
                                    color={'mkyfive.50'}
                                    mb={3}
                                    mt={1}
                                    as="h5"
                                    fontSize={{ base: '2xl', md: 'xl' }}
                                    textAlign={'center'}
                                >
                                    {data.date}
                                </Heading>
                            </>
                        ) : (
                            <Heading
                                fontSize={{
                                    base: '3xl',
                                    md: '3xl',
                                }}
                                fontWeight="extrabold"
                                letterSpacing="tight"
                                marginBottom={3}
                            >
                                {data.name}
                            </Heading>
                        )}
                        <Text size="lg" color={'mkyfive.50'} marginBottom={3}>
                            {data.fund}
                        </Text>
                        <Flex
                            w="100%"
                            justifyContent={'center'}
                            alignItems="center"
                        >
                            {data.topHoldings &&
                            data.topHoldings.length !== 0 ? (
                                <>
                                    <Text
                                        size="lg"
                                        color={'mkyfive.50'}
                                        // fontWeight={800}
                                    >
                                        Top Three Holdings:&nbsp;
                                    </Text>
                                    {data.topHoldings.map((ticker, i) => (
                                        <Text
                                            key={i}
                                            size="lg"
                                            color={'mkyfive.50'}
                                            // fontWeight={800}
                                        >
                                            {`${ticker}`}
                                            {i >= 2 ? ' ' : ','}
                                            &nbsp;
                                        </Text>
                                    ))}{' '}
                                </>
                            ) : (
                                ''
                            )}
                        </Flex>
                    </CardContent>
                </CardWithAvatar>
                <Flex
                    boxSizing="border-box"
                    alignItems="center"
                    justifyContent="center"
                    w="100%"
                    maxH={'540px'}
                    flexDirection={{ base: 'column' }}
                    // backgroundColor={'white'}
                    gap={2}
                    borderBottomRadius={'lg'}
                >
                    {/* <Image
                borderRadius={'xl'}
                // maxW="4xl"
                h={'100%'}
                src={data.graph}
                alt="graph"
            /> */}

                    <Carousel
                        slides={data.graphs ?? [houseModel ? data.src : '']}
                    />

                    <Flex
                        w="100%"
                        justifyContent={'center'}
                        alignItems="center"
                        flexDirection={{
                            base: 'column',
                        }}
                    >
                        <Flex pb={0}>
                            {data.returnsNumber ? (
                                <>
                                    <Text
                                        size="lg"
                                        color={'mkyfive.50'}
                                        fontWeight={800}
                                    >
                                        Returns: &nbsp;
                                    </Text>
                                    <Text
                                        size="lg"
                                        color={'mkyfive.50'}
                                        fontWeight={800}
                                    >
                                        {data.returnsNumber}
                                        &nbsp;
                                    </Text>
                                </>
                            ) : (
                                false
                            )}{' '}
                        </Flex>
                        {data.returnsThru ? (
                            <Text
                                // size="sm"
                                fontSize={15}
                                color={'mkyfive.50'}
                                fontWeight={800}
                                as="i"
                            >
                                {`${data.returnsThru}`}
                            </Text>
                        ) : (
                            false
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    </Box>
);

export default IntroSection;
