import * as React from 'react';
import {
    FormControl,
    NumberDecrementStepper,
    NumberIncrementStepper,
    NumberInput,
    NumberInputField,
    NumberInputStepper,
} from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

const HookNumberInput = ({
    name,
    control,
    min,
    max,
    validateTotal,
    disabled = false,
    active = true,
}) => (
    <FormControl isInvalid={disabled}>
        <Controller
            control={control}
            name={name}
            render={({ field: { ref, onChange, ...restField } }) => (
                <NumberInput
                    {...restField}
                    onChange={onChange}
                    min={min}
                    defaultValue={0}
                    // placeholder={0}
                    borderRadius="10px"
                    {...(restField.value
                        ? {
                              bg: 'mkyfive.50',
                              color: 'mkyone.500',
                              //   borderRadius: '10px',
                          }
                        : {})}
                    _hover={{
                        bg: 'mkyfive.50',
                        color: 'mkyone.500',
                        // borderRadius: '10px',
                    }}
                    _focus={{ bg: 'mkyfive.50', color: 'mkyone.500' }}
                    clampValueOnBlur={true}
                    onBlur={(d) => validateTotal(onChange, d)}
                    size="lg"
                    maxW={32}
                    disabled={(disabled ? !restField.value : false) || !active}
                >
                    <NumberInputField
                        fontWeight={restField.value ? 800 : 300}
                        ref={ref}
                        name={restField.name}
                    />
                    <NumberInputStepper>
                        <NumberIncrementStepper />
                        <NumberDecrementStepper />
                    </NumberInputStepper>
                </NumberInput>
            )}
        />
    </FormControl>
);

export default HookNumberInput;
