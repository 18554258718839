// import React from 'react'
// import {
//   Box,
//   Button,
//   ButtonGroup,
//   Container,
//   HStack,
//   Icon,
//   Input,
//   InputGroup,
//   InputLeftElement,
//   Stack,
//   Text,
//   useBreakpointValue,
//   useColorModeValue,
// } from '@chakra-ui/react'
// const ViewAllocations = () => {
//   return (
//     <Box paddingTop={10} minH="100vh">
//       <Text fontSize={"4xl"} fontWeight={"bold"}>Get Allocations</Text>
//     </Box>
//   )
// }

// export default ViewAllocations;
import {
    Box,
    Button,
    ButtonGroup,
    Container,
    HStack,
    Icon,
    Input,
    InputGroup,
    InputLeftElement,
    Stack,
    Text,
    Heading,
    useBreakpointValue,
    useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { useEffect } from 'react';
import { FiSearch } from 'react-icons/fi';
import { getAllUsersAllocations } from '../../../utils/api';
import { MemberTable } from './MemberTable';

const ViewAllocations = () => {
    const isMobile = useBreakpointValue({
        base: true,
        md: false,
    });

    const [users, setUsers] = React.useState([]);

    const getAllUsersAllocation = async () => {
        await getAllUsersAllocations()
            .then((res) => {
                let allUsers = res.data.data.getAllUsers.Users;
                console.log(allUsers);

                allUsers = allUsers.filter((el) => el.FirstName);

                setUsers(allUsers);
            })
            .catch((err) => {
                console.log('getAllUsersAllocation', err);
            });
    };

    useEffect(() => {
        getAllUsersAllocation();
    }, []);

    return (
        <Box minH="100vh">
            <Container
                py={3}
                maxW={'8xl'}
                zIndex={10}
                position={'relative'}
                bg={'mkytwo.500'}
                borderRadius={'xl'}
                boxShadow="dark-lg"
                color="mkyfive.50"
                minH="100vh"
            >
                <Box
                    bg="bg-surface"
                    boxShadow={{
                        base: 'none',
                        md: useColorModeValue('sm', 'sm-dark'),
                    }}
                    borderRadius={useBreakpointValue({
                        base: 'none',
                        md: 'lg',
                    })}
                    ml={7}
                    mr={7}
                    borderRightRadius={'xl'}
                    backgroundColor={'mkytwo.500'}
                    minHeight="100vh"
                >
                    <Stack spacing="5">
                        <Box
                            px={{
                                base: '4',
                                md: '6',
                            }}
                            pt="5"
                        >
                            <Stack
                                direction={{
                                    base: 'column',
                                    md: 'row',
                                }}
                                justify="space-between"
                            >
                                <Heading>View Allocations </Heading>
                                {/* <InputGroup maxW="xs">
                <InputLeftElement pointerEvents="none">
                  <Icon as={FiSearch} color="muted" boxSize="5" />
                </InputLeftElement>
                <Input placeholder="Search" />
              </InputGroup> */}
                            </Stack>
                        </Box>
                        <Box overflowX="auto">
                            {users ? (
                                <MemberTable members={users} />
                            ) : (
                                <>Loading</>
                            )}
                        </Box>
                        <Box
                            px={{
                                base: '4',
                                md: '6',
                            }}
                            pb="5"
                        >
                            {/* <HStack spacing="3" justify="space-between">
              {!isMobile && (
                <Text color="muted" fontSize="sm">
                  Showing 1 to 5 of 42 results
                </Text>
              )}
              <ButtonGroup
                spacing="3"
                justifyContent="space-between"
                width={{
                  base: 'full',
                  md: 'auto',
                }}
                variant="secondary"
              >
                <Button>Previous</Button>
                <Button>Next</Button>
              </ButtonGroup>
            </HStack> */}
                        </Box>
                    </Stack>
                </Box>
            </Container>
        </Box>
    );
};

export default ViewAllocations;
