import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
} from '@chakra-ui/react';
import { useState, useRef, useEffect } from 'react';

import TwoFAModal from './TwoFA';
import QuestionsModal from './QuestionsModal';
import ProfileModal from './ProfileModal';

import { useSelector } from 'react-redux';

const SpecializedModal = ({ isOpen, onOpen, onClose }, children) => {
    const settingsStore = useSelector((state) => state.settings);

    const [called, setCalled] = useState(0);
    const [modalType, setModalType] = useState(settingsStore.ModalType); // Questions | Profile | TwoFA
    const [ModalBody, setModalBody] = useState(<>Error 49: Modal Error</>);
    const [canOverlayClick, setCanOverlayClick] = useState(true);

    const initialRef = useRef();

    const whichModal = (modalType) => {
        switch (modalType) {
            case 'TwoFA':
                setModalBody(
                    <TwoFAModal
                        onOpen={onOpen}
                        initialRef={initialRef}
                        onClose={onClose}
                    />
                );
                break;
            case 'Questions':
                setModalBody(
                    <QuestionsModal
                        onOpen={onOpen}
                        initialRef={initialRef}
                        onClose={onClose}
                        setCanOverlayClick={setCanOverlayClick}
                    />
                );
                break;
            case 'Profile':
                setModalBody(
                    <ProfileModal
                        onOpen={onOpen}
                        initialRef={initialRef}
                        onClose={onClose}
                    />
                );
                break;
            default:
                setModalBody(<>Error 49: Modal Error</>);
                break;
        }
    };

    useEffect(() => {
        whichModal(settingsStore.ModalType);
    }, [settingsStore.ModalType]);

    return (
        <>
            <Modal
                closeOnOverlayClick={canOverlayClick}
                isCentered
                isOpen={isOpen}
                onClose={() => {
                    setCalled(called + 1);
                    onClose();
                }}
                motionPreset={
                    called % 2 === 0 ? 'slideInBottom' : 'slideInRight'
                }
            >
                <ModalOverlay />
                <ModalContent>{ModalBody}</ModalContent>
            </Modal>
        </>
    );
};

export default SpecializedModal;
