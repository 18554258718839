import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints, mode } from '@chakra-ui/theme-tools';
import { theme } from '@chakra-ui/pro-theme';

import '@fontsource/roboto'; // Defaults to weight 400.
import '@fontsource/source-code-pro';

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const breakpoints = createBreakpoints({
  sm: '40em',
  md: '52em',
  lg: '64em',
  xl: '80em',
});
const styles = {
  global: props => ({
    body: {
      color: mode('#1C1B1B', 'whiteAlpha.900')(props),
      bg: mode('gray.100', '#1C1B1B')(props),
    },
  }),
};

const myTheme = extendTheme({
  colors: {
    ...theme.colors,
    mkyone: {
      900: '#21194c', // main Deep Purple
      800: '#2d2963',
      700: '#35326f',
      600: '#3d3b7a',
      500: '#424282',
      400: '#5b5d93',
      300: '#7678a5',
      200: '#9b9ebf',
      100: '#c2c4d9',
    },
    mkytwo: {
      // Retro Purple
      900: '#1e2764',
      800: '#2a377b',
      700: '#314187',
      600: '#3a4b93',
      500: '#40529c', // main Retro Purple
      400: '#5c6ba9',
      300: '#7886b8',
      200: '#9ea8cd',
      100: '#c4cae1',
    },
    mkythree: {
      900: '#28518a',
      800: '#3470ab',
      700: '#3b81bf',
      600: '#4193d3',
      500: '#46a1e2',
      400: '#54aee5',
      300: '#67bcea', // main Icy Blue
      200: '#8ccff2',
      100: '#b7e2f7',
    },
    mkyfour: {
      900: '#292e30', // main Stone
      800: '#3a4346',
      700: '#48565b',
      600: '#576a70',
      500: '#637880',
      400: '#7b8c92',
      300: '#94a0a5',
      200: '#b5babd',
      100: '#d5d5d5',
    },
    mkyfive: {
      900: '#00030d',
      800: '#02151c',
      700: '#10262e',
      600: '#1c3641',
      500: '#25424e',
      400: '#435a65',
      300: '#61747d',
      200: '#8c9aa0',
      100: '#b9c2c6',
      50: '#e3e6e8', // main Frost
    },
  },
  fonts: {
    heading: 'Source Code Pro, serif',
    body: 'Roboto, sans-serif',
    mono: 'Roboto, monospace',
  },
  breakpoints,
  theme,
  styles,
  config,
});

export default myTheme;
