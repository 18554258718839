import React from 'react';
import { Button } from '@chakra-ui/react';

export const  PurpleButton = ({ children, onClick, ...rest }) => {
  return (
    <Button
    {...rest}
      rounded={'full'}
      size={'lg'}
      fontWeight={'normal'}
      bg="mkytwo.500"
      color="mkyfive.50"
      colorScheme= 'mkyfive.50'
      border='2px'
      variant= 'outline'
      transform= 'transform 0.3s ease'
      cursor='pointer'
      _hover={{ 
          bg: 'mkytwo.500', 
          color: 'mkyfive.50',
          boxShadow: 'dark-lg',
      }}
      px={6}
      onClick={onClick}
  >
      {children}
  </Button>
  );
}
