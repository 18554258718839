import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import RISK_VARS from '../data/risk-thresholdVars.json';

import {
    Alert,
    AlertDescription,
    AlertIcon,
    AlertTitle,
    Box,
    Button,
    Container,
    Heading,
    Link,
    SimpleGrid,
    Stack,
    Tooltip,
    useToast,
} from '@chakra-ui/react';
import AllocationsTable from '../components/table.jsx';

import {
    analyzeRiskScore,
    analyzeRisk2,
    analyzeRiskScoreValidate,
    summify,
    convertData,
} from '../utils';

import MKYData from './../data/data.json';

import {
    saveAllocationTable,
    getAllocationsTable,
    getAlgorithmVariables,
} from './../utils/api';

import { useDispatch, useSelector } from 'react-redux';
import { onSettingsChange } from '../redux/Features/Settings';

import { useForm, useWatch } from 'react-hook-form';
import {
    getActiveHouseModelsList,
    getActiveManagersList,
} from '../utils/webiny';

const Allocations = ({ onModalOpen }) => {
    const ABSOLUTE_ALLOCATION_TOTAL = 100;

    const {
        Model,
        // Manager,
        Messages: { AllocationSubHeader },
    } = MKYData;

    const [loading, setLoading] = useState(false);

    const [total, setTotal] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [displayTotal, setDisplayTotal] = useState(0);
    const [msg, setMsg] = useState(AllocationSubHeader['Skip Form']);
    const [successMsg, setSuccessMsg] = useState(
        AllocationSubHeader['Success']
    );
    const [comingFrom, setComingFrom] = useState('');

    const [HouseModels, setHouseModels] = useState([]);
    const [Managers, setManagers] = useState([]);
    const [
        flatListActiveManagersAndHouseModels,
        setFlatListActiveManagersAndHouseModels,
    ] = useState([]);

    // controls if this is the user's first allocation or not
    const [initialAllocations, setInitialAllocations] = useState(true);

    const [allocated, setAllocated] = useState(false);

    const toast = useToast();

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const settingsStore = useSelector((store) => store.settings);

    const { handleSubmit, control, setValue, getValues } = useForm({
        mode: 'onTouched',
        reValidateMode: 'onChange',
    });

    const stalker = useWatch({ control });

    const { state } = useLocation();

    const openModal = (where) => {
        dispatch(
            onSettingsChange({
                name: 'ModalType',
                value: 'Questions',
            })
        );
        dispatch(
            onSettingsChange({
                name: 'ModalFrom',
                value: where,
            })
        );
        onModalOpen();
    };

    const setAllocationUI = (allocations) => {
        for (const [key, value] of Object.entries(allocations)) {
            if (flatListActiveManagersAndHouseModels.includes(key))
                setValue(key, value, { shouldTouch: false });
        }
    };

    const setManagersAndModels = async () => {
        // set up form with active managers and models from webiny
        await getActiveManagersList()
            .then((res) => {
                let managers = res.data.data.listManagers.data;
                // managers.push(' ');
                console.log('YLYOYODFGF');

                let actives = [];

                setManagers(managers);

                managers.forEach((m) =>
                    actives.push(m.allocationMetaData.value)
                );

                setFlatListActiveManagersAndHouseModels(actives);
            })
            .catch((err) =>
                console.log(
                    `setManagersAndModels failed on Allocations Page, ${err}`
                )
            );
        await getActiveHouseModelsList()
            .then((res) => {
                let hms = res.data.data.listHouseModels.data;
                let actives = [];

                setHouseModels(hms);

                hms.forEach((m) => actives.push(m.allocationMetaData.value));

                setFlatListActiveManagersAndHouseModels(actives);
            })
            .catch((err) =>
                console.log(
                    `setManagersAndModels failed on Allocations Page, ${err}`
                )
            );
    };

    const getAllocationTable = async () => {
        await getAllocationsTable(settingsStore.ID)
            .then((res) => {
                let arr = res.data.data.getAllocationData.Allocation;

                let alloc = arr.reduce(
                    (obj, item) =>
                        Object.assign(obj, { [item.Name]: item.Amount }),
                    {}
                );

                setAllocationUI(alloc);
            })
            .catch((err) =>
                toast({
                    title: 'Form Update Failed.',
                    description: `Allocations Retrieval Failed! ${err}`,
                    status: 'failure',
                    duration: 9000,
                    isClosable: true,
                })
            );
    };

    useEffect(() => {
        // setup page
        setManagersAndModels();
    }, []);

    useEffect(() => {
        // Doings things based on what pages we are coming from
        if (state) {
            if (state?.from) {
                switch (state.from) {
                    case 'Skip Form':
                        // if we are coming from the form intro page and user decided to skip the survey
                        setComingFrom('Skip Form');
                        setMsg(AllocationSubHeader['Skip Form']);
                        break;
                    case 'Profile':
                        // if we are coming from profile modal
                        setComingFrom('Profile');
                        console.log('WRITING PROFILE');
                        setMsg(AllocationSubHeader['Profile']);
                        setInitialAllocations(false);

                        if (!settingsStore.ID) {
                            console.log('ERROR: no ID saved');
                        }

                        getAllocationTable();
                        break;
                    case 'Form End':
                        // if we are coming from the end of the questions modal
                        setComingFrom('Form End');
                        setMsg(AllocationSubHeader['Form End']);
                        setSuccessMsg(AllocationSubHeader['Form End']);

                        //
                        break;
                    default:
                        setMsg(AllocationSubHeader['Skip Form']);
                }
            } else {
                console.log('NO STATE FROM COMING TO ALLOCATION PAGE');
            }
        }

        // sets score from saved risk score
        let rScore = null;

        if (state && state.score) rScore = Number(state.score);
        else if (settingsStore.RiskScore) {
            rScore = Number(settingsStore.RiskScore);
            // commented out due to excessive occurances of the alert
            //  toast({
            //     title: 'Form Updated.',
            //     description:
            //         'Form updated using our assessment! Feel free to alter the allocations or proceed forward via the submit button!',
            //     status: 'success',
            //     duration: 9000,
            //     isClosable: true,
            // });
        } else return; // Dont proceed (auto fill the form) if there is no score coming from the questions form or a score stored in redux

        analyzeRscore(rScore);
    }, [HouseModels, Managers]);

    const allocationDump = () => {
        // function dumps current allocations saved and in UI
    };

    let analyzeRscore = async (rScore) => {
        console.log('Risk Score:', rScore);

        const riskVars = await getAlgoVars();

        let analyzed = analyzeRisk2(
            rScore,
            riskVars.Conditions,
            riskVars.Payloads
        );
        let val = analyzeRiskScoreValidate(rScore);

        if (analyzed) {
            for (const [key, value] of Object.entries(analyzed)) {
                if (flatListActiveManagersAndHouseModels.includes(key)) {
                    setValue(key, value, { shouldTouch: false });
                }
            }
        } else {
            console.log('ERROR OCCURED ANALYZING');
        }

        let filtered = convertData(stalker);

        let summed = summify(filtered);

        if (summed > ABSOLUTE_ALLOCATION_TOTAL) {
            setTotal(total);
        } else setTotal(summed);
    };

    useEffect(() => {
        console.log('updated');

        analyzeRscore(Number(settingsStore.RiskScore));
    }, settingsStore.RiskScore);

    useEffect(() => {
        console.log('YOLOOO', comingFrom);
        // Unregistered user has come to the page
        // force them to fill out 1st contact page to attain id and set up account
        if (!settingsStore.Authorized) {
            openModal('AllocationsNoID');
            setComingFrom('Unregistered User');
            setMsg(AllocationSubHeader['Unregistered User']);
        } else {
            setComingFrom('Skip Form');
            setMsg(AllocationSubHeader['Skip Form']);
        }
    }, [settingsStore.Authorized]);

    useEffect(
        // sets total that is displayed to the user
        () => {
            let vals = stalker;

            let payload = {};

            let temp = getValues();

            if (temp) {
                for (const [key, value] of Object.entries(temp)) {
                    if (value) payload[key] = value;
                }
                // can take this away
                if (payload)
                    dispatch(
                        onSettingsChange({
                            name: 'Allocations',
                            value: payload,
                        })
                    );
            }

            // stalker returns all form data, convert data removes all empty key value pairs that are null, undefined, or empty strings
            let filtered = convertData(vals);

            // summify reduces all values in the filtered object and returns the sum (total)
            let summed = summify(filtered);

            // sets total
            setDisplayTotal(summed);
        },
        [stalker, total] /*[stalker]*/
    );

    useEffect(
        // sets total that is displayed to the user
        () => {
            if (settingsStore.AllocationsPending)
                dispatch(
                    onSettingsChange({
                        name: 'AllocationsPending',
                        value: false,
                    })
                );
        },
        [settingsStore.AllocationsPending] /*[stalker]*/
    );

    useEffect(() => {
        // check if we are topped off or have space left
        // if (displayTotal === 100) {
        //     setAllocated(true);
        // } else {
        //     setAllocated(false);
        // }

        setAllocated(displayTotal === 100 ? true : false);

        if (displayTotal > 100) {
            if (!disabled) {
                toast.closeAll();
                toast({
                    title: 'Only Allocate 100%.',
                    description:
                        'You have surpassed 100 Percent! Please readjust your allocations so it is equal to 100%.',
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                });
            }
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [total, displayTotal]);

    //  fix?
    const getAlgoVars = async () => {
        const vars = await getAlgorithmVariables();

        return vars.data.data.getSettings.Settings;
    };

    const verifyInput = (formOnChange, d) => {
        let newTotal = 0;
        // get value from input field

        // preparing to get current total
        let filtered = convertData(stalker);

        // gets current total
        let summed = summify(filtered);
        newTotal = summed;

        let value = Number(d.target.value);

        newTotal = total + value;
        setTotal(newTotal);

        return formOnChange(value === 0 ? 0 : value);
    };

    const onSubmit = async (data, e) => {
        e.preventDefault();
        setLoading(true);
        // dispatch(onSettingsChange({ name: 'Allocations', value: data }));

        let filtered = convertData(data);

        // prepare data to send to graphQL
        const payload = [];

        for (const [key, value] of Object.entries(filtered)) {
            payload.push({
                Name: key,
                Amount: value,
            });
        }

        // send to backend
        await saveAllocationTable(settingsStore.ID, payload, initialAllocations)
            .then((res) => {
                if (comingFrom !== 'Profile') {
                    dispatch(
                        onSettingsChange({
                            name: 'AllocationsComplete',
                            value: true,
                        })
                    );
                    setLoading(false);
                    navigate('/onboard');
                } else {
                    toast.closeAll();

                    toast({
                        title: 'Allocations Updated.',
                        description:
                            'Your allocation changes have been recorded and will be updated on the next business day. Adding a new Master’s Model to your allocation my require an additional 1-2 days to complete the change.',
                        status: 'success',
                        duration: 9000,
                        isClosable: true,
                    });
                    setLoading(false);
                }
            })
            .catch((err) => {
                console.error(`Save Allocation error: ${err}`);
            });
    };

    return (
        <>
            <Box
                position={'relative'}
                w={{ base: '100%', lg: '83%' }}
                borderBottomRadius={'xl'}
            >
                <Container maxW={'8xl'} position={'relative'}>
                    <Stack
                        flex={1}
                        color={'mkyfive.50'}
                        justify={{ lg: 'center' }}
                        py={{ base: 4, md: 5, xl: 5 }}
                    >
                        <Box>
                            <Heading
                                color={'mkytwo.500'}
                                mb={2}
                                fontSize={{ base: '3xl', md: '3xl' }}
                                textAlign={'left'}
                            >
                                Allocate Investments
                            </Heading>
                        </Box>
                        <Alert
                            {...(allocated
                                ? {
                                      status: 'success',
                                      boxShadow: `0 0 3px rgba(0,0,0,0.3)`,
                                      bg: 'green.500',
                                      color: 'mkyfive.50',
                                  }
                                : { bg: 'mkyone.700' })}
                            variant="subtle"
                            flexDirection="column"
                            height="fit-content"
                            borderTopLeftRadius={5}
                            borderTopRightRadius={5}
                        >
                            <AlertTitle textAlign={'left'}></AlertTitle>
                            <AlertTitle mt={3} mb={1} fontSize="lg">
                                <Link
                                    {...(comingFrom === 'Unregistered User'
                                        ? {
                                              onClick: () =>
                                                  openModal('AllocationsNoID'),
                                              _hover: {
                                                  color: 'mkythree.300',
                                              },
                                          }
                                        : {
                                              textDecoration: 'none',
                                          })}
                                >
                                    {allocated ? successMsg : msg}
                                </Link>
                            </AlertTitle>
                            <AlertDescription maxWidth="8xl" fontSize="md">
                                If you would like our recommendations please{' '}
                                <Link
                                    textDecoration={'underline'}
                                    onClick={() => openModal('Allocations')}
                                >
                                    click here
                                </Link>{' '}
                                to complete our investment assessment
                            </AlertDescription>
                        </Alert>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <SimpleGrid
                                columns={{ base: 1, lg: 2 }}
                                spacing={5}
                            >
                                <AllocationsTable
                                    control={control}
                                    headers={['House Models', '% Allocated']}
                                    rows={HouseModels}
                                    type="Model"
                                    validateTotal={verifyInput}
                                    disabled={
                                        comingFrom === 'Unregistered User'
                                    }
                                />
                                <AllocationsTable
                                    control={control}
                                    headers={[
                                        "Manager's Models",
                                        '% Allocated',
                                    ]}
                                    rows={Managers}
                                    type="Manager"
                                    validateTotal={verifyInput}
                                    disabled={
                                        comingFrom === 'Unregistered User'
                                    }
                                />
                            </SimpleGrid>
                            <Alert
                                mt={3}
                                borderBottomLeftRadius={5}
                                borderBottomRightRadius={5}
                                {...(allocated
                                    ? {
                                          status: 'success',
                                          boxShadow: `0 0 3px rgba(0,0,0,0.3)`,
                                          bg: 'green.500',
                                          color: 'mkyfive.50',
                                      }
                                    : {
                                          bg: 'mkyone.700',
                                      })}
                                variant="subtle"
                                height={'fit-content'}
                                px={{ base: 5, md: '9vw' }}
                            >
                                <Box
                                    fontColor="mkyfive.900"
                                    fontSize={{ base: 'xs', sm: 'md' }}
                                    textAlign={'left'}
                                    width="100%"
                                    fontWeight={700}
                                    fontStyle="Source Code"
                                >
                                    <Box>
                                        Left To Allocate: {100 - displayTotal} %
                                        <Box fontSize="xs">
                                            (Needs to be at 0 to proceed)
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    fontColor="mkyfive.900"
                                    fontSize={{ base: 'xs', sm: 'md' }}
                                    textAlign={'right'}
                                    width="100%"
                                    fontWeight={700}
                                    display="flex"
                                    justifyContent={'flex-end'}
                                    alignItems="center"
                                    fontStyle="Source Code"
                                >
                                    <Tooltip
                                        label="Great"
                                        aria-label="A tooltip"
                                    >
                                        <AlertIcon
                                            boxSize="20px"
                                            {...(allocated
                                                ? {
                                                      status: 'success',
                                                      boxShadow: `0 0 3px rgba(0,0,0,0.3)`,
                                                      bg: 'green.500',
                                                      color: 'mkyone.900',
                                                  }
                                                : {
                                                      bg: 'mkyone.700',
                                                      color: 'mkyfive.50',
                                                  })}
                                        />
                                    </Tooltip>
                                    {/* Uncommenting this will ruin your day/week,
                                    Leave it alone {total} */}
                                    <Tooltip
                                        label="Needs to be at 100 to proceed!"
                                        aria-label="Total left tool tip"
                                    >
                                        <p>
                                            {' '}
                                            Total Allocated: {
                                                displayTotal
                                            } %{' '}
                                        </p>
                                    </Tooltip>
                                </Box>
                            </Alert>
                            <Button
                                marginTop={3}
                                fontSize={'sm'}
                                minWidth={'sm'}
                                fontWeight={400}
                                variant={'solid'}
                                boxShadow="0 2px 5px rgba(0,0,0,0.3)"
                                textShadow="0 2px 3px rgba(0,0,0,0.3)"
                                isLoading={loading}
                                href={'#'}
                                {...(allocated
                                    ? {
                                          bg: 'mkytwo.500',
                                          color: 'mkyfive.50',
                                      }
                                    : {
                                          bg: 'mkyone.700',
                                      })}
                                rounded={'full'}
                                _hover={{
                                    bg: 'mkytwo.100',
                                    color: 'mkyone.900',
                                }}
                                type={'submit'}
                                disabled={!allocated}
                            >
                                {allocated
                                    ? 'SUBMIT CHOICES'
                                    : disabled
                                    ? 'Please only allocate 100%'
                                    : 'Allocate all 100% to the Models'}
                            </Button>
                            {process.env.NODE_ENV === 'development' ? (
                                <Button
                                    bg="blue"
                                    onClick={() => {
                                        dispatch(
                                            onSettingsChange({
                                                name: 'Allocations',
                                                value: {},
                                            })
                                        );

                                        window.location.reload(false);
                                    }}
                                >
                                    Empty Allocations
                                </Button>
                            ) : (
                                <></>
                            )}
                        </form>
                    </Stack>
                </Container>
            </Box>
        </>
    );
};

export default Allocations;
