import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    // USER SETUP
    ID: '',
    RiskScore: 0,
    Allocations: {},
    Authorized: false, // TWO FA Authorized
    SignUpPageAuth: false, // IB later going to set this to allow access to signup page, if false it will redirect user to / when they try ot access the IB signup page
    SurveyComplete: false,
    AllocationsPending: false,
    AllocationsComplete: false,

    // Remember 2FA Page
    TwoFAState: 'Email',

    // Survey
    SurveyPage: 0,
    contactRecieved: false,
    ModalFrom: false, // remembers where we are coming from when opening the modal

    // Modal Control
    ModalOpen: true,
    ModalType: '',

    // allows access to dashboard
    AccessLevel: '',
};

export const SettingsSlice = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        settingsHydrate: (state, action) => {
            // do not do state = action.payload it will not update the store
            // state = { ...action.payload.survey, ...state };
            return action.payload;
        },
        onSettingsChange: (state, action) => {
            state[action.payload.name] = action.payload.value;
        },
        onChangeAlt: (state, action) => {
            state = { ...action.payload, ...state };
        },
        settingsLogOut: () => initialState,
        openModal: (state, action) => {
            state = { ModalOpen: true, ...state };
        },
        closeModal: (state, action) => {
            state = { ModalOpen: false, ...state };
        },
        setModalType: (state, action) => {
            let type = action.payload;
            console.log('Modal Type: ', type);
            switch (type) {
                case 'Questions':
                    break;
                case 'Profile':
                    break;
                case '2FA':
                    break;
                default:
                    type = 'ERROR 50: Modal Error';
            }
            state = { ModalType: type, ...state };
        },
    },
});

export const { onSettingsChange, settingsLogOut, settingsHydrate } =
    SettingsSlice.actions;

export default SettingsSlice.reducer;
