import {
  AspectRatio,
  Image,
  Box,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import * as React from 'react';

export const CardWithAvatar = props => {
  const { action, avatarProps, children, ...rest } = props;
  return (
    <Flex
      direction="column"
      align={'center'}
      justifyContent={'center'}
      alignItems={'center'}
      // mx="5px"
      my="auto"
      color={'mkyfive.50'}
      maxH={'300px'}
      {...rest}
    >
      <AspectRatio maxW={{base: '150px', lg: '180px'}} ratio={1 / 1} mt="-10"
        w="100%"
        size='2xl'
        borderRadius='full'>
      <Image
        borderRadius='full'
        {...avatarProps}
        objectFit='cover'
      />
      </AspectRatio>
      <Box
        position="absolute"
        top="4"
        insetEnd={{
          base: '6',
          md: '8',
        }}
      >
        {action}
      </Box>
      {children}
    </Flex>
  );
};
