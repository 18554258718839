import React from 'react';
import {
    ChakraProvider,
    Box,
    VStack,
    Grid,
    useDisclosure,
    Alert,
    AlertIcon,
    Button,
} from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import { Routes, Route, useNavigate } from 'react-router-dom';
import myTheme from './theme';
import {
    About,
    House,
    Contact,
    Investments,
    IBSignup,
    Managers,
    Allocations,
    Dashboard,
} from './pages';

import SpecializedModal from './modalPages/SpecializedModal.jsx';

import Hero from './components/hero';
import Navbar from './components/navbar';
import Footer from './components/footer';
import ScrollToTop from './components/ScrollToTop';
import Cookie from './components/cookie/cookieConsent.jsx';

import { PurpleButton } from './components/buttons/PurpleButton';

import { useDispatch, useSelector } from 'react-redux';
import { onSettingsChange } from './redux/Features/Settings';

import ReactGA from 'react-ga';
const TRACKING_ID = 'G-80VLVG42PW'; // OUR_TRACKING_ID
ReactGA.initialize(TRACKING_ID);

function App() {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const settingsStore = useSelector((state) => state.settings);

    const dispatch = useDispatch();

    const navigate = useNavigate();

    const ReturnToAllocations = () => {};

    return (
        <>
            <ScrollToTop />
            <ChakraProvider resetCSS theme={myTheme}>
                <SpecializedModal
                    isOpen={isOpen}
                    onOpen={onOpen}
                    onClose={onClose}
                />
                <Box textAlign="center" fontSize="xl" bg="mkyfive.50">
                    {/* blue pages need white letters, every other is purple */}
                    <Navbar onModalOpen={onOpen} />
                    {settingsStore.AllocationsPending ? (
                        <PurpleButton
                            width="fit-content"
                            onClick={() => {
                                dispatch(
                                    onSettingsChange({
                                        name: 'AllocationsPending',
                                        value: false,
                                    })
                                );
                                return navigate('/allocations');
                            }}
                        >
                            {/* prettier-ignore */}
                            <ArrowBackIcon w={5} h={5} /> {'\t\t  '} Return to
                            Allocations
                        </PurpleButton>
                    ) : (
                        <></>
                    )}
                    {/* <Cookie /> */}
                    <Grid minH="100vh">
                        <VStack spacing={8}>
                            <Routes>
                                <Route
                                    exact
                                    path={'/'}
                                    element={<Hero onModalOpen={onOpen} />}
                                />
                                <Route
                                    path={'/home'}
                                    element={<Hero onModalOpen={onOpen} />}
                                />
                                <Route path="/about" element={<About />} />
                                <Route
                                    path="/investments"
                                    element={
                                        <Investments onModalOpen={onOpen} />
                                    }
                                />
                                <Route
                                    path="/house/:name"
                                    element={<House />}
                                />
                                <Route
                                    path="/manager/:name"
                                    element={<Managers onModalOpen={onOpen} />}
                                />
                                <Route
                                    path="/allocations"
                                    element={
                                        <Allocations onModalOpen={onOpen} />
                                    }
                                />
                                <Route
                                    path="/dashboard"
                                    element={<Dashboard />}
                                />

                                <Route path="/contact" element={<Contact />} />

                                <Route path="/onboard" element={<IBSignup />} />
                            </Routes>
                        </VStack>
                    </Grid>
                    <Footer />
                </Box>
            </ChakraProvider>
        </>
    );
}

export default App;
