import { webinySkeletonGQLQuery } from './apiHelper';

import {
    ABOUT_DATA,
    GET_SPECIFIC_MANAGER_DATA,
    MANAGER_DATA,
    MANAGER_THUMBNAILS,
    ACTIVE_MANAGER_LIST,
    ACTIVE_HOUSEMODELS_LIST,
    FOOTER_DATA,
    GET_SPECIFIC_HOUSE_MODEL_DATA,
} from './queries/cms';

export const getAboutData = async (iteration) =>
    await webinySkeletonGQLQuery('getAboutData', ABOUT_DATA, { iteration });

export const getFooterData = async (iteration) =>
    await webinySkeletonGQLQuery('getFooterData', FOOTER_DATA, { iteration });

export const getManagerData = async () =>
    await webinySkeletonGQLQuery('getManagerData', MANAGER_DATA);

export const getSpecificManagerData = async (Manager) =>
    await webinySkeletonGQLQuery(
        'getSpecificManagerData',
        GET_SPECIFIC_MANAGER_DATA,
        { Manager }
    );

export const getManagerThumbs = async (Manager) =>
    await webinySkeletonGQLQuery('getManagerThumbs', MANAGER_THUMBNAILS);

export const getActiveManagersList = async () =>
    await webinySkeletonGQLQuery('getActiveManagerList', ACTIVE_MANAGER_LIST);

export const getActiveHouseModelsList = async () =>
    await webinySkeletonGQLQuery(
        'getActiveManagerList',
        ACTIVE_HOUSEMODELS_LIST
    );

export const getSpecificHouseModelData = async (HouseModel) =>
    await webinySkeletonGQLQuery(
        'getSpecificHouseModelData',
        GET_SPECIFIC_HOUSE_MODEL_DATA,
        { HouseModel }
    );
