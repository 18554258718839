import {
    Flex,
    Stack,
    Text,
    Button,
    Divider,
    HStack,
    Icon,
    Heading,
} from '@chakra-ui/react';
import * as React from 'react';
import {
    AiFillEdit,
    AiOutlineAreaChart,
    AiOutlineCloseSquare,
} from 'react-icons/ai';

const DashboardMenu = ({ isOpen, setActive, active, closeMenu }) => {
    const NavButton = (props) => {
        const { icon, label, ...buttonProps } = props;
        return (
            <Button
                variant="ghost-on-accent"
                justifyContent="start"
                {...buttonProps}
            >
                <HStack spacing="3">
                    <Icon as={icon} boxSize="6" color="on-accent-subtle" />
                    <Text fontFamily={'body'} color="on-accent-subtle">
                        {label}
                    </Text>
                </HStack>
            </Button>
        );
    };

    return (
        <Flex
            position={'fixed'}
            as="section"
            minWidth={'10%'}
            minH="100vh"
            borderRightRadius={'xl'}
            backgroundColor={'mkytwo.500'}
            bg="bg-canvas"
        >
            <Flex
                flex="1"
                bg="bg-accent"
                maxW={{ base: 'full', sm: 'xs' }}
                py={{ base: '6', sm: '8' }}
                px={{ base: '4', sm: '6' }}
            >
                <Stack justify="space-between" spacing="1" width="full">
                    <Stack spacing="8" shouldWrapChildren>
                        <Flex width={'100%'} justifyContent={'center'}>
                            <Heading size={'md'} color={'mkyfive.50'}>
                                Dashboard Controls
                            </Heading>
                        </Flex>
                        <Divider />
                        <Stack spacing="1">
                            <NavButton
                                aria-label="View Allocations"
                                // backgroundColor={
                                //     active === 0 ? 'mkyfive.50' : ''
                                // }
                                onClick={() => closeMenu()}
                                color={'mkyfive.50'}
                                transition={'.2s'}
                                _hover={{
                                    color:
                                        active === 0
                                            ? 'mkytwo.900'
                                            : 'mkytwo.900',
                                    cursor: 'pointer',
                                }}
                                label="Close Menu"
                                icon={AiOutlineCloseSquare}
                            />
                            <NavButton
                                aria-label="View Allocations"
                                backgroundColor={
                                    active === 0 ? 'mkyfive.50' : ''
                                }
                                onClick={() => setActive(0)}
                                color={active === 0 ? '' : 'mkyfive.50'}
                                transition={'.2s'}
                                _hover={{
                                    color:
                                        active === 0
                                            ? 'mkytwo.900'
                                            : 'mkytwo.900',
                                    cursor: 'pointer',
                                }}
                                label="View Allocations"
                                icon={AiOutlineAreaChart}
                            />
                            <NavButton
                                aria-label="Change Allocations"
                                backgroundColor={
                                    active === 1 ? 'mkyfive.50' : ''
                                }
                                onClick={() => setActive(1)}
                                transition={'.2s'}
                                color={
                                    active === 1 ? 'mkythree.900' : 'mkyfive.50'
                                }
                                _hover={{
                                    color:
                                        active === 1
                                            ? 'mkytwo.900'
                                            : 'mkytwo.900',
                                    cursor: 'pointer',
                                }}
                                label="Change Allocations"
                                icon={AiFillEdit}
                            />
                        </Stack>
                    </Stack>
                </Stack>
            </Flex>
        </Flex>
    );
};

export default DashboardMenu;
