import {
    Button,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spinner,
    useToast,
    Box,
    SimpleGrid,
} from '@chakra-ui/react';

import { get2FA, verify2FA } from '../utils/api';

import { FormControl, FormLabel, Input } from '@chakra-ui/react';
import { useCallback, useEffect, useState } from 'react';

import MKYAlert from '../components/alert';
import { useDispatch, useSelector } from 'react-redux';
import { onSettingsChange, settingsLogOut } from '../redux/Features/Settings';
import { surveyLogOut } from '../redux/Features/Survey';
import { useNavigate } from 'react-router-dom';

const ProfileModal = ({ initialRef, onClose, onOpen }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const settingsStore = useSelector((store) => store.settings);
    const surveyStore = useSelector((store) => store.survey);

    const [user, setUser] = useState('');

    useEffect(() => {
        if (user == '') {
            const userName = surveyStore.FirstName + ' ' + surveyStore.LastName;
            setUser(userName);
            console.log(surveyStore.FirstName);
        }
    }, []);

    const ProfileHeader = useCallback(() => <>Profile</>, []);

    const ProfileBody = () => {
        return (
            <Box display={'flex'} flexDirection={{ base: 'column' }} gap={2}>
                <Box>
                    <h1>
                        Hello {surveyStore.FirstName} {surveyStore.LastName} (
                        {surveyStore.Email})
                    </h1>
                </Box>
                <Box
                    display={'flex'}
                    flexDirection={{ base: 'column', md: 'row' }}
                    gap={2}
                >
                    <Button
                        // bg="mkyfive.50"
                        _hover={{
                            bg: 'mkytwo.500',
                            color: 'mkyfive.50',
                        }}
                        fontSize={'md'}
                        fontWeight={400}
                        variant={'solid'}
                        rounded={'full'}
                        borderColor="mkytwo.500"
                        color="mkytwo.500"
                        borderWidth={4}
                        // _hover={{ bg: 'mkytwo.100', color: 'mkyone.900' }}
                        // colorScheme="mkythree"
                        onClick={() => {
                            navigate('/allocations', {
                                state: { from: 'Profile' },
                            });
                            onClose();
                        }}
                    >
                        View/Change Allocations
                    </Button>
                    <Button
                        fontSize={'md'}
                        fontWeight={400}
                        variant={'solid'}
                        rounded={'full'}
                        _hover={{ bg: 'mkytwo.100', color: 'mkyone.900' }}
                        colorScheme="mkytwo"
                        onClick={() => {
                            dispatch(
                                onSettingsChange({
                                    name: 'ModalType',
                                    value: 'Questions',
                                })
                            );
                        }}
                    >
                        Update Risk Profile
                    </Button>
                </Box>
                <Box display={'flex'} justifyContent="center">
                    <Button
                        // bg="mkyfive.50"
                        _hover={{
                            bg: 'mkytwo.500',
                            color: 'mkyfive.50',
                        }}
                        fontSize={'md'}
                        fontWeight={400}
                        variant={'solid'}
                        rounded={'full'}
                        borderColor="mkytwo.500"
                        color="mkytwo.500"
                        borderWidth={4}
                        // _hover={{ bg: 'mkytwo.100', color: 'mkyone.900' }}
                        // colorScheme="mkythree"
                        onClick={() => {
                            dispatch(settingsLogOut());
                            dispatch(surveyLogOut());
                            navigate('/');
                            onClose();
                            console.log(settingsStore);
                        }}
                    >
                        Log out
                    </Button>
                </Box>
            </Box>
        );
    };

    const ProfileFooter = () => {
        return <></>;
    };

    return (
        <>
            <ModalHeader>
                <ProfileHeader />
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
                <ProfileBody />
            </ModalBody>

            <ModalFooter>
                <ProfileFooter />
            </ModalFooter>
        </>
    );
};

export default ProfileModal;
