import { Box, Flex, HStack, Image, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';

const Carousel = ({ slides }) => {
    const [onMouseEntered, setOnMouseEntered] = useState(0);

    const arrowStyles = {
        cursor: 'pointer',
        pos: 'absolute',
        top: '50%',
        w: 'auto',
        mt: '-22px',
        p: '16px',
        color: 'white',
        fontWeight: 'bold',
        autoplaySpeed: 5000,
        fontSize: '18px',
        transition: '0.6s ease',
        borderRadius: '0 3px 3px 0',
        userSelect: 'none',
        opacity: 1,
        _hover: {
            opacity: 0.8,
            bg: 'black',
        },
    };

    // const slides = [
    //     {
    //         img: 'https://images.pexels.com/photos/2599537/pexels-photo-2599537.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    //     },
    //     {
    //         img: 'https://images.pexels.com/photos/2714581/pexels-photo-2714581.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    //     },
    //     {
    //         img: 'https://images.pexels.com/photos/2878019/pexels-photo-2878019.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260',
    //     },
    //     {
    //         img: 'https://images.pexels.com/photos/1142950/pexels-photo-1142950.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    //     },
    //     {
    //         img: 'https://images.pexels.com/photos/3124111/pexels-photo-3124111.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940',
    //     },
    // ];

    const [currentSlide, setCurrentSlide] = useState(0);
    const slidesCount = slides.length;

    const prevSlide = () => {
        setCurrentSlide((s) => (s === 0 ? slidesCount - 1 : s - 1));
    };

    const nextSlide = () => {
        setCurrentSlide((s) => (s === slidesCount - 1 ? 0 : s + 1));
    };

    const setSlide = (slide) => {
        setCurrentSlide(slide);
    };

    const handleMouseEnter = () => {
        setOnMouseEntered(1);
    };

    const handleMouseLeave = () => {
        setOnMouseEntered(0);
    };

    useEffect(() => {
        const intervalId = setInterval(() => {
            //assign interval to a variable to clear it.
            if (onMouseEntered) return currentSlide;
            if (currentSlide !== slidesCount) {
                nextSlide();
            } else setCurrentSlide(0);
        }, 5000);

        return () => clearInterval(intervalId); //This is important
    }, [onMouseEntered]);

    const carouselStyle = {
        transition: 'all .5s',
        ml: `-${currentSlide * 100}%`,
    };

    return (
        <Flex
            position={'relative'}
            w="full"
            h="fit-content"
            bg="#fff"
            _dark={{
                bg: '#3e3e3e',
            }}
            pb={2}
            borderRadius={10}
            alignItems="center"
            justifyContent="center"
            onMouseEnter={() => handleMouseEnter()}
            onMouseLeave={() => handleMouseLeave()}
        >
            <Flex borderRadius={10} w="full" overflow="hidden" pos="relative">
                <Flex h="350px" w="full" {...carouselStyle}>
                    {slides.map((slide, sid) => (
                        <Box
                            key={`slide-${sid}`}
                            boxSize="full"
                            shadow="md"
                            flex="none"
                            // onMouseEnter={() => handleMouseEnter()}
                            // onMouseLeave={() => handleMouseLeave()}
                        >
                            <Image
                                src={slide}
                                alt="carousel image"
                                boxSize="full"
                                w="full"
                                backgroundSize="cover"
                                // onMouseEnter={() => handleMouseEnter()}
                                // onMouseLeave={() => handleMouseLeave()}
                            />
                        </Box>
                    ))}
                </Flex>
                <Text {...arrowStyles} left="0" onClick={prevSlide}>
                    &#10094;
                </Text>
                <Text {...arrowStyles} right="0" onClick={nextSlide}>
                    &#10095;
                </Text>
            </Flex>
            <HStack
                justify="center"
                pos="absolute"
                bottom="0px"
                w="full"
                m={-1}
            >
                {Array.from({
                    length: slidesCount,
                }).map((_, slide) => (
                    <Box
                        key={`dots-${slide}`}
                        cursor="pointer"
                        boxSize={['7px', null, '15px']}
                        // m="0 10px"
                        m={2}
                        bg={
                            currentSlide === slide
                                ? 'blackAlpha.800'
                                : 'blackAlpha.500'
                        }
                        rounded="50%"
                        display="inline-block"
                        transition="background-color 0.6s ease"
                        _hover={{
                            bg: 'blackAlpha.800',
                        }}
                        onClick={() => setSlide(slide)}
                    ></Box>
                ))}
            </HStack>
        </Flex>
    );
};

export default Carousel;
