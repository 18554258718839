import React, { useCallback, useEffect, useState } from 'react';

import {
    Button,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Spinner,
    Divider,
    Box,
    Text,
    Checkbox,
    useToast,
    Input,
    NumberInput,
    NumberInputField,
} from '@chakra-ui/react';

import { saveClientContact, saveClientData } from './../utils/api';

import { summify, filterStrings } from './../utils/';

import HookInput from '../components/hookInput';
import HookSelect from '../components/hookSelect';
import Alert from '../components/alert';

import { useForm } from 'react-hook-form';

import QuestionsData from './../data/questions.json';
import { useDispatch, useSelector } from 'react-redux';
import { onSettingsChange } from '../redux/Features/Settings';
import { onChange } from '../redux/Features/Survey';
import { useNavigate } from 'react-router-dom';

const QuestionsModal = ({
    initialRef,
    onClose,
    onOpen,
    setCanOverlayClick,
}) => {
    const surveyStore = useSelector((state) => state.survey);
    const settingsStore = useSelector((state) => state.settings);

    const dispatch = useDispatch();

    const {
        control,
        watch,
        handleSubmit,
        register,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm();

    const [contactRecieved, setContactRecieved] = useState(
        settingsStore.contactRecieved
    );
    const [authorized, setAuthorized] = useState(surveyStore.EmailAuthorized);
    const [notAuthorized, setNotAuthorized] = useState(false);

    const [changeRiskScore, setChangeRiskScore] = React.useState(0);

    const [gQL, setGQL] = useState(false);
    const [alertMsg, setAlertMsg] = useState(false);
    const [alertType, setAlertType] = useState(false);
    const [comingFrom, setComingFrom] = useState('');

    const [showDegree, setShowDegree] = useState('none');

    const [page, setPage] = useState(settingsStore.SurveyPage ?? 0); // controls the question page we are on

    const navigate = useNavigate();

    const toast = useToast();

    const isValidPage = (check) =>
        check >= 0 && check < QuestionsData.Pages.length;

    useEffect(() => {
        // backup set variable in case
        setPage(settingsStore.SurveyPage ?? 0);

        if (contactRecieved) setPage(1);

        if (settingsStore.ModalFrom) {
            console.log(settingsStore.ModalFrom);
            setComingFrom(settingsStore.ModalFrom);
            if (window.location.pathname !== '/allocations') {
                dispatch(onSettingsChange({ name: 'ModalFrom', value: '' }));
                return;
            }
        }
    }, []);

    useEffect(() => {
        switch (comingFrom) {
            case 'AllocationsNoID':
                // stops modal from being closed if not filled out
                setCanOverlayClick(false);
                // toast({
                //     title: 'Need to fill out first step of form.',
                //     description:
                //         'Please fill out the first step of the form to proceed!',
                //     status: 'info',
                //     duration: 18000,
                //     isClosable: true,
                //     position: 'top',
                // });
                break;
            default:
        }
    }, [comingFrom]);

    useEffect(() => {
        // auto run submit
        if (page === 6) {
            onSubmit();
        }
    }, [page]);

    useEffect(() => {
        let degree = Number(watch('CollegeGrad'));
        if (degree === 3) {
            setShowDegree('block');
            setValue('HighestDegree', 0);
        } else if (degree === 0) {
            setShowDegree('none');
            setValue('HighestDegree', 0);
        }
    }, [watch('CollegeGrad')]);

    const onSubmit = async (values = false) => {
        if (page === 0 && !authorized) {
            setNotAuthorized(true);
            setAlertMsg('Please agree to the terms to continue.');
            setAlertType('error');
            return;
        }

        let res;
        setGQL(true);
        setAlertMsg('Pending');
        setAlertType('warning');

        if (values) {
            for (const key in values) {
                if (key === 'PhoneNumber' && values[key][0] !== '1')
                    values[key] = '1' + values[key];
                else if (key !== 'PhoneNumber' && !isNaN(values[key]))
                    values[key] = Number(values[key]);

                if (key === 'Age') values[key] = String(values[key]);
                dispatch(onChange({ name: key, value: values[key] })); // redux here to confirm data saved
            }
        }

        try {
            switch (page) {
                case 0:
                    res = await saveClientContact(
                        values.Email,
                        values.PhoneNumber,
                        values.FirstName,
                        values.LastName
                    ).then((res) => {
                        if (!res.data.data.saveClientContact.ID)
                            throw new Error(
                                'ID not received, something went wrong'
                            );
                        dispatch(
                            onSettingsChange({
                                name: 'ID',
                                value: res.data.data.saveClientContact.ID,
                            })
                        );

                        if (comingFrom === 'AllocationsNoID') {
                            // coming from allocations page, so time to return
                            onClose();
                        }
                    });

                    setContactRecieved(true);
                    dispatch(
                        onSettingsChange({
                            name: 'contactRecieved',
                            value: true,
                        })
                    );

                    break;
                case 6:
                    let filtered = filterStrings(surveyStore);
                    let sum = summify(filtered);
                    const riskScore = sum / 14;

                    dispatch(
                        onChange({
                            name: 'RiskScore',
                            value: String(riskScore),
                        })
                    );

                    dispatch(
                        onSettingsChange({
                            name: 'RiskScore',
                            value: riskScore,
                        })
                    );
                    let payload = {
                        ID: settingsStore.ID,
                        ...surveyStore,
                    };
                    await saveClientData(payload).then((res) => {
                        dispatch(
                            onSettingsChange({
                                name: 'SurveyComplete',
                                value: true,
                            })
                        );
                        dispatch(
                            onSettingsChange({
                                name: 'SurveyPage',
                                value: 1,
                            })
                        );
                        // TODO: send to page where modal says u cant access this survey anymore
                        navigate('/allocations', {
                            state: { from: 'Form End' },
                        });
                        onClose();
                    });
                    break;
                default:
                    console.log(page);
            }
        } catch (e) {
            console.error('Question ModalError: ', e);
            setAlertType('error');
            setAlertMsg('Error 40: Issues submitting survey');
            setGQL(true);
            return;
        }

        setGQL(false);

        if (page === QuestionsData.Pages.length - 1) return;

        // increment page
        if (isValidPage(page)) {
            dispatch(onSettingsChange({ name: 'SurveyPage', value: page + 1 }));
            setPage(page + 1);
        }
    };

    const QuestionsHeader = useCallback(
        () =>
            isValidPage(page) ? (
                <>{QuestionsData.Sections[page]}</>
            ) : (
                <>Modal Broken</>
            ),
        [page]
    );

    const InputGen = (QuestionData) => {
        return QuestionsData.Pages[page].map((q, i) => {
            let rules = (p) => {
                switch (p) {
                    case 'Email':
                        return {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: 'Invalid email address',
                        };
                    case 'Phone Number':
                        return {
                            value: /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/,
                            message: 'Invalid phone number',
                        };
                    default:
                        return {};
                }
            };

            if (q.Type === 'Select')
                return (
                    <HookSelect
                        key={i}
                        ref={i === 0 ? initialRef : undefined}
                        name={q.Name}
                        type={q.Type}
                        options={q.Options}
                        register={register}
                        helperText={q?.HelperText}
                        placeholder={q.Placeholder}
                        required={q.Required}
                        pattern={rules(q.Pattern)}
                        styles={{
                            display: `${q?.Dependent ? showDegree : 'block'}`,
                            margin: '0.3rem 0',
                        }}
                        err={
                            errors[q.Name.replace(' ', '')]
                                ? errors[q.Name.replace(' ', '')]
                                : false
                        }
                        validate={q.Validate ? q.Validate : {}}
                    />
                );
            else if (q.Type === 'Checkbox')
                return (
                    <Checkbox
                        defaultChecked={authorized}
                        onChange={() => setAuthorized(!authorized)}
                        margin="1rem 0"
                        size="md"
                        colorScheme="mkyone"
                        isInvalid={notAuthorized}
                    >
                        I agree to the{' '}
                        <a
                            style={{
                                color: '#0000FF',
                                textDecoration: 'underline',
                            }}
                            rel="noreferrer"
                            target="_blank"
                            href="https://marketocracy-front-end.s3.us-east-2.amazonaws.com/PDFs/Marketocracy+Masters+Terms+of+Use+v1.1.pdf"
                        >
                            terms of use
                        </a>{' '}
                        of this website
                    </Checkbox>
                );
            else if (q.Type === 'Intro')
                if (comingFrom === 'managerPage')
                    return (
                        <>
                            <Box key={i}>
                                <Text
                                    fontSize={'lg'}
                                    color={'mkyone.900'}
                                    textAlign={'left'}
                                >
                                    Upon completing this investment survey, we
                                    will provide you with recommendations of how
                                    we would allocate your investment across our
                                    models and managers. From there you can come
                                    back and view any managers model performance
                                    and return to the allocations page by
                                    clicking on the “My Profile” button on the
                                    top right of the page.”
                                </Text>
                            </Box>
                        </>
                    );
                else
                    return (
                        <>
                            <Box key={i}>
                                <Text
                                    fontSize={'lg'}
                                    color={'mkyone.900'}
                                    textAlign={'left'}
                                >
                                    If you would answer some questions to help
                                    us understand your individual situation, we
                                    will provide you with{' '}
                                    <b>our recommendations</b> of how you should
                                    invest your money across our managers and
                                    models.
                                </Text>
                                <br />
                                <Text
                                    fontSize={'md'}
                                    color={'mkyone.900'}
                                    textAlign={'left'}
                                >
                                    If you already know the manager or models
                                    that you would like to invest in and would{' '}
                                    <b>
                                        prefer to not answer the following
                                        questions
                                    </b>
                                    , please choose “Invest myself”, otherwise
                                    choose “Help me Invest”
                                </Text>
                            </Box>
                        </>
                    );
            else if (q.Type === 'Results')
                return (
                    <>
                        <Box
                            display="flex"
                            alignContent="center"
                            justifyContent="center"
                            key={i}
                        >
                            <Spinner color="green.400" size="xl" />
                        </Box>
                    </>
                );
            else
                return (
                    <HookInput
                        key={i}
                        ref={i === 0 ? initialRef : undefined}
                        name={q.Name}
                        type={q.Type}
                        register={register}
                        helperText={q?.HelperText}
                        placeholder={q.Placeholder}
                        required={q.Required}
                        pattern={rules(q.Pattern)}
                        styles={{
                            margin: '0.3rem 0',
                            color: 'mkytwo.500',
                        }}
                        borderWidth={2}
                        borderColor="mkytwo.200"
                        err={
                            errors[q.Name.replace(' ', '')]
                                ? errors[q.Name.replace(' ', '')]
                                : false
                        }
                        validate={q.Validate ? q.Validate : {}}
                    />
                );
        });
    };

    const HelpMessageGen = useCallback(() => {
        switch (page) {
            case 0:
                if (comingFrom === 'AllocationsNoID')
                    return (
                        <>
                            <Text color="mkyone.400">
                                We need to ask you a few questions before you
                                can set your own allocations.
                            </Text>
                        </>
                    );
                else
                    return (
                        <>
                            <Text color="mkyone.400">
                                We need to ask you a few questions to help you
                                invest{' '}
                                <span>
                                    <i>
                                        (or you can skip to the end on the next
                                        page)
                                    </i>
                                </span>
                                .
                            </Text>
                        </>
                    );
            default:
                return <></>;
        }
    }, [page, comingFrom]);

    const QuestionsBody = () => {
        return (
            <>
                <HelpMessageGen />
                <InputGen QuestionData={QuestionsData} />{' '}
            </>
        );
    };

    const QuestionsFooter = () => {
        if (comingFrom === 'managerPage' && page === 1)
            return (
                <Box display={'flex'} justifyContent="right" w="100%">
                    <Button
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'solid'}
                        color={'mkyone.900'}
                        borderWidth={4}
                        borderColor={'mkyone.500'}
                        rounded={'full'}
                        _hover={{ bg: 'mkyone.500', color: 'mkyfive.50' }}
                        mr={4}
                        isLoading={gQL}
                        type="submit"
                    >
                        Start Investment Survey
                    </Button>
                </Box>
            );
        else if (page === 1)
            return (
                <Box display={'flex'} justifyContent="space-between" w="100%">
                    <Button
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'solid'}
                        colorScheme={'mkyone'}
                        rounded={'full'}
                        _hover={{ bg: 'mkytwo.100', color: 'mkyone.900' }}
                        mr={4}
                        isLoading={gQL}
                        onClick={() => {
                            navigate('/allocations', {
                                state: { from: 'Skip Form' },
                            });
                            onClose();
                        }}
                    >
                        Invest Myself
                    </Button>
                    <Button
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'solid'}
                        color={'mkyone.900'}
                        borderWidth={4}
                        borderColor={'mkyone.500'}
                        rounded={'full'}
                        _hover={{ bg: 'mkyone.500', color: 'mkyfive.50' }}
                        mr={4}
                        isLoading={gQL}
                        type="submit"
                    >
                        Help Me Invest
                    </Button>
                    {process.env.NODE_ENV == 'development' ? (
                        <Box>
                            <NumberInput
                                key="riskScoreInpu"
                                id="riskScoreInput"
                                htmlSize={4}
                                width="auto"
                                value={changeRiskScore}
                                onChange={(val) => setChangeRiskScore(val)}
                            >
                                <NumberInputField />
                            </NumberInput>
                            <Button
                                fontSize={'sm'}
                                fontWeight={400}
                                variant={'solid'}
                                color={'mkyone.900'}
                                borderWidth={4}
                                borderColor={'mkyone.500'}
                                rounded={'full'}
                                _hover={{
                                    bg: 'mkyone.500',
                                    color: 'mkyfive.50',
                                }}
                                mr={4}
                                isLoading={gQL}
                                type="submit"
                                onClick={() => {
                                    if (changeRiskScore == 0) {
                                        return 'Please give us a risk score';
                                    }
                                    dispatch(
                                        onSettingsChange({
                                            name: 'RiskScore',
                                            value: changeRiskScore,
                                        })
                                    );
                                    dispatch(
                                        onSettingsChange({
                                            name: 'Allocations',
                                            value: {},
                                        })
                                    );
                                    navigate('/allocations', {
                                        state: { from: 'Skip Form' },
                                    });
                                    onClose();
                                }}
                            >
                                Test Risk Score
                            </Button>
                        </Box>
                    ) : (
                        <></>
                    )}
                </Box>
            );
        else
            return (
                <>
                    <Button
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'solid'}
                        href={'#'}
                        colorScheme={'mkyone'}
                        rounded={'full'}
                        _hover={{ bg: 'mkytwo.100', color: 'mkyone.900' }}
                        onClick={onClose}
                        disabled={comingFrom === 'AllocationsNoID'}
                        mr={4}
                    >
                        Cancel
                    </Button>
                    <Button
                        fontSize={'sm'}
                        fontWeight={400}
                        variant={'solid'}
                        href={'#'}
                        color={'mkyone.900'}
                        borderWidth={4}
                        borderColor={'mkyone.500'}
                        rounded={'full'}
                        _hover={{ bg: 'mkyone.500', color: 'mkyfive.50' }}
                        isLoading={gQL}
                        type="submit"
                    >
                        {comingFrom === 'AllocationsNoID' ? 'Done' : 'Next'}
                    </Button>
                </>
            );
    };

    return (
        <>
            <ModalHeader>
                <QuestionsHeader />
                <Divider />
            </ModalHeader>
            <ModalCloseButton />
            <form onSubmit={handleSubmit(onSubmit)}>
                <ModalBody>
                    {alertType === 'error' ? (
                        <Alert type={alertType} title={alertMsg} />
                    ) : (
                        <></>
                    )}
                    <QuestionsBody />
                </ModalBody>
                <ModalFooter>
                    <QuestionsFooter />
                </ModalFooter>
            </form>
        </>
    );
};

export default QuestionsModal;

// export { QuestionsHeader, QuestionsBody, QuestionsFooter };
