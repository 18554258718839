import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    textDecoration,
    Tooltip,
} from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { onSettingsChange } from '../redux/Features/Settings';
import HookNumberInput from './hookNumberInput';

const AllocationsTable = ({
    headers,
    rows,
    control,
    type,
    validateTotal,
    disabled,
}) => {
    const navigate = useNavigate();

    const dispatch = useDispatch();

    return (
        <TableContainer bg={'mkytwo.400'} boxShadow="0 0px 6px rgba(0,0,0,0.4)">
            <Table variant="simple" bg="mkytwo.200">
                <Thead bg={'mkyone.700'}>
                    <Tr>
                        {headers.map((h, i) => (
                            <Th fontSize={'md'} color={'mkyfive.50'} key={i}>
                                {h}
                            </Th>
                        ))}
                    </Tr>
                </Thead>
                <Tbody>
                    {rows.map((res, i) => (
                        <Tr bg={'mkytwo.400'} key={i} wordBreak="break-word">
                            <Td
                                height={'5rem'}
                                display="flex"
                                flexDirection={'row'}
                                alignItems="center"
                                gap="5"
                                cursor="pointer"
                                fontSize={'md'}
                                border="0px"
                                fontWeight={300}
                                _hover={{
                                    background: 'mkyfive.50',
                                    color: 'mkytwo.500',
                                }}
                                onClick={() => {
                                    dispatch(
                                        onSettingsChange({
                                            name: 'AllocationsPending',
                                            value: true,
                                        })
                                    );
                                    if (type === 'Manager')
                                        return navigate(
                                            '/manager/' +
                                                res.allocationMetaData.label
                                                    .split(' ')
                                                    .slice(0, 2)
                                                    .join(' ')
                                        );
                                    else
                                        return navigate(
                                            '/house/' +
                                                res.allocationMetaData.label
                                        );
                                }}
                            >
                                {res.allocationMetaData.label}
                                {!res.active ? (
                                    <Tooltip
                                        label="This Model is not currently accepting any new allocations."
                                        fontSize="md"
                                        marginLeft="4rem"
                                    >
                                        {/* <FontAwesomeIcon icon="fa-solid fa-circle-info" /> */}
                                        <i class="fa-solid fa-circle-info"></i>
                                    </Tooltip>
                                ) : (
                                    <></>
                                )}
                            </Td>
                            <Td border="0px" width="5px">
                                <HookNumberInput
                                    name={res.allocationMetaData.value}
                                    control={control}
                                    min={0}
                                    max={100}
                                    validateTotal={validateTotal}
                                    disabled={disabled}
                                    active={res.active}
                                />
                            </Td>
                        </Tr>
                    ))}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default AllocationsTable;
