export const MANAGER_DATA = `
query ManagerCards {
	listManagers (
    sort: order_ASC
  ) {
    data {
      Name
      Fund
      Abbrev
      TopHoldings
      Tag
      Gip
      ModelPreformance
      Title
      Strategy
      Bio
      Bio2
      Link
      Src
      Thumb
      CompositeChart
      Graphs
      YearlyPerformance
    }
  }
}
`;

export const GET_SPECIFIC_MANAGER_DATA = `
query getSpecificManager ($Manager:String!) {
  getManagers (
    where: {
      name: $Manager
    }
  )  {
  	data {
      src
      name
      fund
      abbrev
      topHoldings
      returnsNumber
      returnsThru
      graphs
      title
      strategy
      gip
      modelPerformance
      bio
      bio2
      articles {
        date
        title
        href
        summary
      }
    } 
  }
}
`;

export const MANAGER_THUMBNAILS = `
query ManagerCards {
	listManagers (
    sort: order_ASC
  ) {
    data {
      name
      fund
      abbrev
      tag
      src
    }
  }
}
`;

export const ABOUT_DATA = `
query getAbout ($iteration: String!) {
	getAbout (
    where: {
      iteration: $iteration
    }
  ) {
    data {
      summary
      summaryRt
    }
  }
}
`;

export const ACTIVE_MANAGER_LIST = `
query listManagers {
  listManagers (
    sort: order_ASC
  )
  {
    data {
      name
      allocationMetaData {
        label
        value
      }
      active
    }
  }
}
`;

export const ACTIVE_HOUSEMODELS_LIST = `
query listHouseModels {
  listHouseModels {
    data {
      houseModel
      allocationMetaData {
        label
        value
      }
      topHoldings
      returnsNumber
      returnsThru
      active
    }
  }
}
`;

export const GET_SPECIFIC_HOUSE_MODEL_DATA = `
query getHouseModels ($HouseModel:String!) {
  getHouseModels (
    where: {
      houseModel: $HouseModel
    }
  )  {
  	data {
      mm
      houseModel
      src
      graphs
      pdf
      allocationMetaData {
        label
        value
      }
      topHoldings
      returnsNumber
      returnsThru
      stratTitle
      strategy
      detTitle
      details
      click
      active
    } 
  }
}
`;

export const FOOTER_DATA = `
query getAbout ($iteration: String!) {
	getFooter (
    where: {
      iteration: $iteration
    }
  ) {
    data {
      terms
      privacy
      crs
    }
  }
}
`;
