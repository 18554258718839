import { useState, useEffect } from 'react';
import {
    Box,
    chakra,
    Container,
    Link,
    Stack,
    Text,
    useColorModeValue,
    VisuallyHidden,
} from '@chakra-ui/react';
import {
    FaInstagram,
    FaTwitter,
    FaYoutube,
    FaFacebook,
    FaLinkedin,
} from 'react-icons/fa';

import { getFooterData } from '../utils/webiny';

const packageJson = require('../../package.json');

// Social Media Share Links
// https://www.facebook.com/sharer.php?u=[post-url]
// https://twitter.com/share?url=[post-url]&text=[post-title]&via=[via]&hashtags=[hashtags]
// https://plus.google.com/share?url=[post-url]
// https://www.linkedin.com/shareArticle?url=[post-url]&title=[post-title]
// https://reddit.com/submit?url=[post-url]&title=[post-title]

const postUrl = 'https://www.marketocracymasters.com/';
const postTitle = 'This may be of interest to you.';

const SocialButton = ({ children, label, href }) => {
    return (
        <chakra.button
            bg={useColorModeValue('blackAlpha.100', 'whiteAlpha.100')}
            rounded={'full'}
            w={8}
            h={8}
            cursor={'pointer'}
            as={'a'}
            href={href}
            display={'inline-flex'}
            alignItems={'center'}
            justifyContent={'center'}
            transition={'background 0.3s ease'}
            _hover={{
                bg: useColorModeValue('blackAlpha.200', 'whiteAlpha.200'),
            }}
        >
            <VisuallyHidden>{label}</VisuallyHidden>
            {children}
        </chakra.button>
    );
};

export default function SmallCentered() {
    const [footer, setFooter] = useState(0);

    const getFooterProd = async () => {
        return await getFooterData('PROD')
            .then((res) => {
                console.log(res);
                setFooter(res.data.data.getFooter.data);
            })
            .catch((res) => {
                console.log('getting manager thumbnails failed', res);
            });
    };

    useEffect(() => {
        getFooterProd();
    }, []);

    return (
        <Box
            bg={useColorModeValue('gray.50', 'gray.900')}
            color={useColorModeValue('gray.700', 'gray.200')}
            marginTop={10}
        >
            <Container
                zIndex={100}
                as={Stack}
                maxW={'6xl'}
                py={2}
                spacing={4}
                justify={'center'}
                align={'center'}
            >
                <Stack direction={'row'} spacing={6}>
                    <Link href={'/'}>Home</Link>
                    <Link href={'/contact'}>Contact</Link>
                    <Link href={footer.terms} target="_blank">
                        Terms
                    </Link>
                    <Link href={footer.privacy} target="_blank">
                        Privacy
                    </Link>
                    <Link href={footer.crs} target="_blank">
                        CRS
                    </Link>
                </Stack>
            </Container>

            <Box
                borderTopWidth={1}
                borderStyle={'solid'}
                borderColor={useColorModeValue('gray.200', 'gray.700')}
            >
                <Container
                    as={Stack}
                    maxW={'6xl'}
                    py={4}
                    direction={{ base: 'column', md: 'row' }}
                    spacing={4}
                    justify={{ base: 'center', md: 'space-between' }}
                    align={{ base: 'center', md: 'center' }}
                >
                    <Text>
                        ©{new Date().getFullYear()}
                        {'  '} Marketocracy Masters. All rights reserved
                        <small>
                            {'  '}v{packageJson.version}
                        </small>
                    </Text>
                    <Stack direction={'row'} spacing={6}>
                        <Text>Share: </Text>
                        <SocialButton
                            label={'Twitter'}
                            href={
                                ' https://twitter.com/share?url=https://www.marketocracymasters.com&text=Check this site out.&hashtags=MMCM, Marketocracy Masters, Investing, Capital Management'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaTwitter color="#1da1f2" />
                        </SocialButton>
                        <SocialButton
                            label={'Facebook'}
                            colorScheme={'facebook'}
                            href={
                                'https://www.facebook.com/sharer.php?u=https://www.marketocracymasters.com'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaFacebook color="#1877f2" />
                        </SocialButton>
                        {/* <SocialButton
                            label={'YouTube'}
                            href={'https://youtube.com'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaYoutube />
                        </SocialButton> */}
                        {/* <SocialButton
                            label={'Instagram'}
                            href={'https://instagram.com/marketocracymasters'}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaInstagram />
                        </SocialButton> */}
                        <SocialButton
                            label={'Linkedin'}
                            href={
                                'https://www.linkedin.com/shareArticle?url=https://www.marketocracymasters.com&title=This may be of interest to you.'
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <FaLinkedin color="#0077b5" />
                        </SocialButton>
                    </Stack>
                </Container>
            </Box>
        </Box>
    );
}
